import { AmplitudeAnalyticsClient } from './amplitude';
import { GoogleAnalyticsClient } from './google-analytics';
import * as Events from './events';
import { IAnalyticsEvent } from './types';
import UniversalCookiesManager from '../../utils/cookies/UniversalCookiesManager';

export { Events };

const universalCookieManager = new UniversalCookiesManager();

export class Event {
    public static logEvent(event: IAnalyticsEvent) {
        if (universalCookieManager.getAnalyticsCookies()) {
            GoogleAnalyticsClient.logEvent(event);
            AmplitudeAnalyticsClient.logEvent(event, AmplitudeAnalyticsClient.getClient());
        }
    }
}
