import React from 'react';
import { Intent, Tag } from '@blueprintjs/core';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../utils/theming/theme-type';

interface ITrulyLocalBadge {
    width?: string;
}

const TrulyLocalBadge: React.FunctionComponent<ITrulyLocalBadge> = ({ width }) => {
    const theme: Theme = useTheme();

    return (
        <Tag
            intent={Intent.WARNING}
            large={true}
            css={css`
                font-size: ${theme.fontSize.tertiary};
                line-height: 1.25rem;
                min-width: 1.75rem;
                width: ${width ? width : 'auto'};
                padding: 0.4rem 0.5rem;
            `}>
            {' '}
            Truly local{' '}
        </Tag>
    );
};

export default TrulyLocalBadge;
