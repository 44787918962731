import React from 'react';
import { css } from '@emotion/core';
import { HTMLSelect } from '@blueprintjs/core';
import UniversalCookiesManager from '../../utils/cookies/UniversalCookiesManager';
import { useTheme } from 'emotion-theming';
import Theme from '../../utils/theming/theme-type';
import { useRouter } from 'next/router';

type languageObjectType = {
    label: 'Suomi' | 'English';
    value: 'fi' | 'en';
};

const languages: languageObjectType[] = [
    { label: 'Suomi', value: 'fi' },
    { label: 'English', value: 'en' }
];

const LanguageSelector = () => {
    let universalCookieManager = new UniversalCookiesManager();
    const router = useRouter();

    const theme: Theme = useTheme();

    const onChangeHandler = (event): void => {
        let pathname = document.location.pathname;
        if (document.location.pathname.includes('fi')) {
            pathname = document.location.pathname.substring(3);
        }
        universalCookieManager.setLanguage(event.currentTarget.value);
        router.push(`${pathname ? pathname : '/'}`, `${pathname ? pathname : '/'}`, {
            locale: event.currentTarget.value
        });
    };

    return (
        <HTMLSelect
            options={languages}
            onChange={onChangeHandler}
            iconProps={{
                icon: 'translate',
                intent: 'warning'
            }}
            value={router.locale}
            minimal={true}
            css={css`
                select {
                    font-weight: bold;
                    color: ${theme.colors.primary};
                    height: 2rem;
                }
            `}
        />
    );
};

export default LanguageSelector;
