import React, { useState } from 'react';
import { IPanelProps } from '@blueprintjs/core';
import { css } from '@emotion/core';
import ReactCodeInput from 'react-verification-code-input';
import Theme from '../../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';

const CodeInputForm: React.FunctionComponent<IPanelProps> = () => {

  const theme: Theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCompleteHandler = () => {
    setIsLoading(true);
    /**
     * Here goes a call to the passed Redux action
     */
  }

  return (
    <form className={'flex flex-col justify-between h-full m-1'}>
      <ReactCodeInput type={'number'}
                      fields={6}
                      fieldWidth={45}
                      fieldHeight={70}
                      autoFocus={true}
                      loading={isLoading}
                      onComplete={onCompleteHandler}
                      placeholder={['1', '2', '3', '4', '5', '6']}
                      css={css`
                        margin-bottom: 1rem;
                        div > input {
                          color: ${theme.colors.primary};
                          font-weight: bold;

                          :focus {
                            border-color: ${theme.colors.primary};
                            caret-color: ${theme.colors.primary};
                            color: ${theme.colors.primary};
                          }
                        }
                      `} />
      <p>
        Fill the input form using the code sent to your email to verify your email.
      </p>
    </form>
  );
};

export default CodeInputForm;
