import { AnyAction } from 'redux';
import * as ActionTypes from './action.types';
import * as Types from "./types";
import produce from 'immer';
import EmailPanel from './components/components/components/register-form/email-panel';
import { EMAIL_PANEL } from './components/components/registration-panel';

const reducer = produce(
  (state: Types.IAuthState = {
    registrationError: {
      emailConfirmationError: null,
      usernameError: null
    },
    currentGlobalAuthPanel: 'login',
    currentRegistrationPanelStack: [{ component: EmailPanel, title: EMAIL_PANEL }],
    currentLoginPanelStack: [{ component: EmailPanel, title: EMAIL_PANEL }],
    status: 'unauthenticated',
    inProgress: false,
    user: null,
    isCodeResent: false
  }, action: AnyAction) => {

    const { type, payload } = action;

    switch(type){

      case ActionTypes.AUTH_SET_ERROR_HEIGHT: {
        const { errorHeight } = payload;
        state.errorHeight = errorHeight;
        break;
      }

      case ActionTypes.AUTH_SET_GLOBAL_PANEL: {
        const { newGlobalPanel } = payload;
        state.currentGlobalAuthPanel = newGlobalPanel;
        break;
      }

      case ActionTypes.AUTH_APPEND_PANEL: {
        const { newPanel } = payload;
        state.currentRegistrationPanelStack = [...state.currentRegistrationPanelStack, newPanel]
        break;
      }

      case ActionTypes.AUTH_REMOVE_LAST_PANEL: {
        state.currentRegistrationPanelStack = state.currentRegistrationPanelStack.slice(0, -1);
        break;
      }

      case ActionTypes.AUTH_SET_EMAIL: {
        const { email } = payload;
        state.email = email;
        break;
      }

      case ActionTypes.AUTH_SET_PASSWORD: {
        const { password } = payload;
        state.password = password;
        break;
      }

      case ActionTypes.AUTH_LOGIN_IN_PROGRESS: {
        state.inProgress = true;
        state.loginError = null;
        break;
      }

      case ActionTypes.AUTH_REGISTRATION_USERNAME_CHECK_EXISTS: {
        state.registrationError = null;
        state.isUsernameAvailable = false;
        break;
      }

      case ActionTypes.AUTH_REGISTRATION_USERNAME_CHECK_DOES_NOT_EXIST: {
        state.registrationError = null;
        state.isUsernameAvailable = true;
        break;
      }

      case ActionTypes.AUTH_REGISTRATION_USERNAME_CHECK_FAILED: {
        const { error } = payload;
        state.registrationError.usernameError = error;
        break;
      }

      case ActionTypes.AUTH_LOGIN_FAILED: {
        const { error } = payload;
        state.loginError = error;
        state.status = 'unauthenticated';
        state.inProgress = false;
        state.user = null;
        break;
      }

      case ActionTypes.AUTH_LOGIN_SUCCEEDED: {
        const { user } = payload;
        state.user = user;
        state.loginError = null;
        state.status = 'authenticated';
        state.inProgress = false;
        // state.email = '';
        state.password = '';
        break;
      }

      case ActionTypes.AUTH_REGISTRATION_SET_ADDITIONAL_INFORMATION: {
        const { additionalInformation } = payload;
        state.additionalInformation = additionalInformation;
        break;
      }

      case ActionTypes.AUTH_REGISTRATION_IN_PROGRESS: {
        state.inProgress = true;
        state.user = null;
        state.status = 'unauthenticated';
        state.registrationError = null;
        break;
      }

      case ActionTypes.AUTH_REGISTRATION_SUCCEEDED: {
        const { user } = payload;
        state.inProgress = false;
        state.status = 'unauthenticated';
        state.user = user;
        break;

      }

      case ActionTypes.AUTH_REGISTRATION_FAILED: {
        const { error } = payload
        state.registrationError = error;
        state.status = 'unauthenticated';
        state.inProgress = false;
        state.user = null;
        break;
      }

      case ActionTypes.AUTH_CONFIRMATION_IN_PROGRESS: {
        state.inProgress = true;
        state.user = null;
        state.status = 'unauthenticated';
        state.registrationError.emailConfirmationError = null;
        break;
      }

      case ActionTypes.AUTH_CONFIRMATION_SUCCEEDED: {
        state.inProgress = false;
        state.status = 'authenticated';
        state.registrationError.emailConfirmationError = null;
        state.email = '';
        state.password = '';
        break;
      }

      case ActionTypes.AUTH_CONFIRMATION_FAILED: {
        const { error } = payload;
        state.status = 'unauthenticated';
        state.registrationError.emailConfirmationError = error;
        state.inProgress = false;
        break;
      }

      case ActionTypes.AUTH_RESEND_CONFIRMATION_SUCCEEDED: {
        state.inProgress = false;
        state.isCodeResent = true;
        state.status = 'unauthenticated';
        state.registrationError.emailConfirmationError = null;
        state.email = '';
        state.password = '';
        break;
      }

      case ActionTypes.AUTH_RESEND_CONFIRMATION_FAILED: {
        const { error } = payload;
        state.isCodeResent = false;
        state.status = 'unauthenticated';
        state.registrationError.emailConfirmationError = error;
        state.inProgress = false;
        break;
      }

      case ActionTypes.AUTH_SET_COGNITO_USER_PAYLOAD: {
        const { payload: userPayload } = payload;
        state.cognitoUserPayload = userPayload;
        break;
      }

      default:  {
        return state;
      }
    }
  });


export default reducer;
