import React from 'react';
import { Intent, Position, Tooltip } from '@blueprintjs/core';
import { Button } from '@components/ui';
import { Router } from '../../../utils/i18n/i18n';
import useMedia from 'use-media';

const ShareableListsButton: React.FunctionComponent = () => {
    const isMobile: boolean = useMedia({ maxWidth: 768 });

    const shareableListPagePushHandler = (): void => {
        Router.push('/lists');
    };

    if (isMobile) {
        return (
            <Button
                icon={'applications'}
                text={'Shareable lists'}
                fill={true}
                alignText={'left'}
                large={true}
                minimal={true}
                intent={Intent.PRIMARY}
                onClick={shareableListPagePushHandler}
            />
        );
    }
    if (!isMobile) {
        return (
            <Tooltip
                content={'Shareable Lists Page'}
                intent={Intent.PRIMARY}
                lazy={true}
                position={Position.BOTTOM}>
                <Button
                    icon={'applications'}
                    fill={true}
                    large={true}
                    minimal={true}
                    intent={Intent.PRIMARY}
                    onClick={shareableListPagePushHandler}
                />
            </Tooltip>
        );
    }
};

export default ShareableListsButton;
