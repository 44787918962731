import { IAnalyticsEvent } from './types';
import { isBrowser } from '@unly/utils';
import firebase from '../../utils/firebase';

export class GoogleAnalyticsClient {
    public static config = (sessionId: string) => {
        if (isBrowser()) {
            // @ts-ignore
            window.gtag('config', trackingId, { session_id: sessionId });
        }
    };

    public static logPageView = (title: string, path: string) => {
        if (isBrowser()) {
            // @ts-ignore
            window.gtag('config', trackingId, {
                page_title: title,
                'page-path': path
            });
        }
    };

    public static logEvent = (event: IAnalyticsEvent) => {
        if (isBrowser()) {
            const eventObject = {
                event_category: event.category ?? '',
                event_label: event.label ?? '',
                release: process.env.NEXT_PUBLIC_APP_VERSION
                    ? process.env.NEXT_PUBLIC_APP_VERSION
                    : '',
                branch: process.env.BRANCH_NAME ? process.env.BRANCH_NAME : '',
                stage: process.env.NEXT_PUBLIC_APP_STAGE
            };

            if (event.value) {
                eventObject['event_value'] = event.value;
            }

            firebase.analytics().logEvent(event.action, eventObject);
        }
    };
}
