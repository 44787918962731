export const AUTH_SET_GLOBAL_PANEL = 'AUTH_SET_GLOBAL_PANEL';
export const AUTH_SET_ERROR_HEIGHT = 'AUTH_SET_ERROR_HEIGHT';

export const AUTH_APPEND_PANEL = 'AUTH_APPEND_PANEL';
export const AUTH_REMOVE_LAST_PANEL = 'AUTH_REMOVE_LAST_PANEL';

export const AUTH_SET_EMAIL = 'AUTH_SET_EMAIL';
export const AUTH_SET_PASSWORD = 'AUTH_SET_PASSWORD';

export const AUTH_LOGIN_IN_PROGRESS = 'AUTH_LOGIN_IN_PROGRESS';
export const AUTH_LOGIN_SUCCEEDED = 'AUTH_LOGIN_SUCCEEDED';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';

export const AUTH_REGISTRATION_SET_ADDITIONAL_INFORMATION =
  'AUTH_REGISTRATION_SET_ADDITIONAL_INFORMATION';

export const AUTH_REGISTRATION_IN_PROGRESS = 'AUTH_REGISTRATION_IN_PROGRESS';
export const AUTH_REGISTRATION_SUCCEEDED = 'AUTH_REGISTRATION_SUCCEEDED';
export const AUTH_REGISTRATION_FAILED = 'AUTH_REGISTRATION_FAILED';

export const AUTH_REGISTRATION_USERNAME_CHECK_IN_PROGRESS = 'AUTH_REGISTRATION_USERNAME_CHECK_IN_PROGRESS';
export const AUTH_REGISTRATION_USERNAME_CHECK_EXISTS = 'AUTH_REGISTRATION_USERNAME_CHECK_EXISTs';
export const AUTH_REGISTRATION_USERNAME_CHECK_DOES_NOT_EXIST = 'AUTH_REGISTRATION_USERNAME_CHECK_DOES_NOT_EXIST';
export const AUTH_REGISTRATION_USERNAME_CHECK_FAILED = 'AUTH_REGISTRATION_USERNAME_CHECK_FAILED';

export const AUTH_CONFIRMATION_IN_PROGRESS = 'AUTH_CONFIRMATION_IN_PROGRESS';
export const AUTH_CONFIRMATION_SUCCEEDED = 'AUTH_CONFIRMATION_SUCCEEDED';
export const AUTH_CONFIRMATION_FAILED = 'AUTH_CONFIRMATION_FAILED';

export const AUTH_RESEND_CONFIRMATION_IN_PROGRESS = 'AUTH_RESEND_CONFIRMATION_IN_PROGRESS';
export const AUTH_RESEND_CONFIRMATION_SUCCEEDED = 'AUTH_RESEND_CONFIRMATION_SUCCEEDED';
export const AUTH_RESEND_CONFIRMATION_FAILED = 'AUTH_RESEND_CONFIRMATION_FAILED';

export const AUTH_CHANGE_PASSWORD_IN_PROGRESS = 'AUTH_CHANGE_PASSWORD_IN_PROGRESS';
export const AUTH_CHANGE_PASSWORD_SUCCEEDED = 'AUTH_CHANGE_PASSWORD_SUCCEEDED';
export const AUTH_CHANGE_PASSWORD_FAILED = 'AUTH_CHANGE_PASSWORD_FAILED';

export const AUTH_SET_COGNITO_USER_PAYLOAD = 'AUTH_SET_COGNITO_USER_PAYLOAD';
