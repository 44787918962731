export class CognitoUserPayloadDTO {
    readonly sub: string;
    readonly aud: string;
    readonly emailVerified: boolean;
    readonly eventId: string;
    readonly authTime: Date;
    readonly iss: string;
    readonly preferredUsername: string;
    readonly exp: Date;
    readonly email: string;
    readonly iat: Date;
    readonly docId: string;
    readonly hasActivePartners: boolean;

    constructor(data: Record<string, any>) {
        this.sub = data['sub'];
        this.aud = data['aud'];
        this.emailVerified = data['email_verified'];
        this.eventId = data['event_id'];
        this.authTime = new Date(data['auth_time']);
        this.iss = data['iss'];
        this.preferredUsername = data['cognito:username'];
        this.exp = new Date(data['exp']);
        this.email = data['email'];
        this.iat = new Date(data['iat']);
        this.docId = data['custom:doc_id'];
        this.hasActivePartners = Boolean(data['custom:has_active_partners']);
    }
}
