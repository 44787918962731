import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Divider, Icon } from '@blueprintjs/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../utils/theming/theme-type';
import { Button } from '@components/ui';

interface IBasketButton {
    isFab?: boolean;
}

const BasketButton: React.FunctionComponent<IBasketButton> = ({ isFab }) => {
    const [basketItems, setBasketItems] = useState<number>(0);
    const theme: Theme = useTheme();

    const onBasketClickHandler = () => {
        setBasketItems(basketItems + 1);
    };

    return (
        <Button
            minimal={!basketItems && true}
            outlined={isFab && !basketItems && true}
            onClick={onBasketClickHandler}
            intent={isFab && !!basketItems ? 'warning' : 'primary'}
            css={css`
                ${!basketItems && 'background-color: white !important'};
                transition: all ease-in-out 0.3s;
                ${isFab &&
                'position: fixed;' +
                    'bottom: 2rem;' +
                    'right: 2rem;' +
                    `${theme.layout.mq[0]} {
                display: none;
              }`}
                > span {
                    display: flex;
                }
            `}>
            <Icon icon={'shopping-cart'} iconSize={25} />
            <Divider
                css={css`
                    display: ${!basketItems && 'none'};
                `}
            />
            <span
                css={css`
                    display: ${!basketItems && 'none'};
                    line-height: 1.5rem;
                `}>
                {basketItems}
            </span>
        </Button>
    );
};

export default BasketButton;
