import React, { Dispatch, SetStateAction } from 'react';
import { Callout, Collapse, Intent, IResizeEntry, ResizeSensor } from '@blueprintjs/core';
import { css } from '@emotion/core';

// TODO Add more error codes
const ERROR_CODE_INCORRECT_UN_PWD = 'NotAuthorizedException';
const ERROR_CODE_USER_NOT_CONFIRMED = 'UserNotConfirmedException';
const ERROR_CODE_PWD_RESET_REQUIRED = 'PasswordResetRequiredException';
const ERROR_CODE_USER_NOT_FOUND = 'UserNotFoundException';

interface IErrorCallout {
  error: Error;
  setErrorHeight?: Dispatch<SetStateAction<number>>;
}

/**
 * This component takes care of adding
 * @param error {Error} Error object that occurred
 * @param setErrorHeight {Function} Function that sets the error height
 */
const ErrorCallout: React.FunctionComponent<IErrorCallout> = ({error, setErrorHeight}) => {

  const errorMessageHandler = (message: string): string => {
    if ( message === ERROR_CODE_INCORRECT_UN_PWD ) return 'Password or/and username are incorrect'
    // TODO Write an error case for an incorrect username
    if ( message === ERROR_CODE_INCORRECT_UN_PWD ) return 'This username is incorrect'
    if ( message === ERROR_CODE_PWD_RESET_REQUIRED ) return 'Sorry, the password requires reset'
    if ( message === ERROR_CODE_USER_NOT_CONFIRMED ) return 'Sorry, looks like this user was not confirmed'
    if ( message === ERROR_CODE_USER_NOT_FOUND ) {
      return 'Sorry, but we could not find this user';
    } else {
      return 'Something wrong has happened!'
    }
  }

  const onResizeHandler = (entries: IResizeEntry[]) => {
    const height: number = entries[entries.length - 1].contentRect.height/16;
    setErrorHeight(height);
  }

  return (
    <Collapse isOpen={!!error} >
      <ResizeSensor onResize={onResizeHandler} >
        <Callout title={'Oops, an error'}
                 intent={Intent.DANGER}
                 css={css`
                   margin: 1rem 0;
                `} >
          {errorMessageHandler(error?.name)}
        </Callout>
      </ResizeSensor>
    </Collapse>
  );
};

export default ErrorCallout;
