import { css } from '@emotion/core';
import React, { useState } from 'react';
import { Checkbox, Dialog, FormGroup, InputGroup, IPanelProps } from '@blueprintjs/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import PasswordPanel from './password-panel';
import * as Actions from '../../../../actions';
import { PASSWORD_PANEL } from '../../registration-panel';
import { ErrorCallout } from '../../../../../../components/common';
import CookieDocument from '../../../../../../components/common/cookie-banner/cookie-document';
import { Button } from '@components/ui';

interface IEmailPanelProps extends IPanelProps {
    setEmailHandler: Dispatch<SetStateAction<string>>;
    setNewGlobalPanelHandler: Dispatch<string>;
    error: Error;
}

const EmailPanel: React.FunctionComponent<IEmailPanelProps> = ({
    openPanel,
    setEmailHandler,
    error,
    setNewGlobalPanelHandler
}) => {
    const { register, handleSubmit, errors } = useForm();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false);
    const [isPolicyDocument, setIsPolicyDocument] = useState<boolean>(false);

    const submitHandler: SubmitHandler<Record<string, any>> = (data) => {
        setEmailHandler(data['registrationEmail']);
        setIsFetching(true);
        /**
         * If the registration call to API was successful and the user was registered
         */
        openPanel({
            component: PasswordPanel,
            title: PASSWORD_PANEL
        });
        setIsFetching(false);
    };

    const setCurrentPanelHandler = (): void => {
        setNewGlobalPanelHandler('login');
    };

    const setPolicyHandler = (): void => {
        setIsPolicyDocument(true);
    };

    const setPolicyToFalse = (): void => {
        setIsPolicyDocument(false);
    };

    const termsChangeHandler = (): void => {
        setAreTermsAccepted(!areTermsAccepted);
    };

    return (
        <form
            onSubmit={handleSubmit(submitHandler)}
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            `}>
            <div>
                <FormGroup
                    label={'Email/telephone number'}
                    labelFor={'email-input'}
                    helperText={'Your email/telephone number'}>
                    <InputGroup
                        placeholder={'Eg. john@doe.com'}
                        name={'registrationEmail'}
                        large={true}
                        type={'text'}
                        id={'registration-email'}
                        inputRef={register({
                            required: true
                        })}
                    />
                </FormGroup>
                <Dialog
                    isOpen={isPolicyDocument}
                    title={'Cookie document'}
                    onClose={setPolicyToFalse}
                    lazy={true}
                    css={css`
                        width: 35rem;
                    `}>
                    <CookieDocument />
                </Dialog>
                <Checkbox
                    labelElement={
                        <span>
                            {' '}
                            I accepted these{' '}
                            <a onClick={setPolicyHandler}> terms and conditions </a>{' '}
                        </span>
                    }
                    defaultChecked={true}
                    // className={'mb-4'}
                    onChange={termsChangeHandler}
                />
            </div>
            <ErrorCallout error={error} />
            <a onClick={setCurrentPanelHandler}> Already have an account? </a>
            <Button
                type={'submit'}
                intent={'primary'}
                large={true}
                loading={isFetching}
                disabled={errors?.registrationEmail || areTermsAccepted}
                text={'Continue'}
            />
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        error: state.authState.error
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setEmailHandler: (email: string) => dispatch(Actions.setEmail(email)),
        setNewGlobalPanelHandler: (newGlobalPanel: string) =>
            dispatch(Actions.setGlobalPanel(newGlobalPanel))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPanel);
