import React from 'react';
import { ForgotPasswordPanel, LoginPanel, RegistrationPanel } from './components';
import CodeInputPanel from './components/code-input-panel';
import { Auth, CognitoUserPayloadDTO } from '@services/auth';
import { connect } from 'react-redux';
import { Router } from '../../../utils/i18n/i18n';

export type CurrentPanel = 'login' | 'registration' | 'code-input' | 'forgot-password';

interface IProps {
    user: CognitoUserPayloadDTO | null | undefined;
    updateUser?: (user: unknown) => void;
    currentGlobalAuthPanel?: CurrentPanel;
}

const ProtectedRoute: React.FunctionComponent<IProps> = ({
    user,
    children,
    currentGlobalAuthPanel
}) => {
    React.useEffect(() => {
        if (!children && user) {
            Router.push('/home');
        }
    }, []);

    if (children && user) {
        return <> {children} </>;
    } else {
        return (
            <main className={'flex justify-center items-center h-screen w-screen bg-white'}>
                {currentGlobalAuthPanel === 'login' && <LoginPanel />}
                {currentGlobalAuthPanel === 'registration' && <RegistrationPanel />}
                {currentGlobalAuthPanel === 'forgot-password' && <ForgotPasswordPanel />}
                {currentGlobalAuthPanel === 'code-input' && <CodeInputPanel />}
            </main>
        );
    }
};

export const authUserChecker = (SSRAuth: typeof Auth): Promise<CognitoUserPayloadDTO> => {
    // Does it really need to be promise if it is already async?
    // I am asking this because ESLint shows this as an error
    return new Promise(async (resolve, reject) => {
        try {
            const user = await SSRAuth.currentAuthenticatedUser();
            const serializedPayload = JSON.parse(
                JSON.stringify(user.signInUserSession.idToken.payload)
            );
            resolve(serializedPayload);
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
};

const mapStateToProps = (state) => {
    return {
        currentGlobalAuthPanel: state.authState.currentGlobalAuthPanel
    };
};

export default connect(mapStateToProps, null)(ProtectedRoute);
