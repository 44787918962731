import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../utils/theming/theming';

interface Props {
    height?: string;
    color?: string;
}

const MaxWidthWrapper = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${(props: Props) => props.color};
    width: 100%;
    > div,
    > article {
        max-width: 1024px;
        width: 100%;
        margin-left: ${theme.layout.sidePadding[0]};
        margin-right: ${theme.layout.sidePadding[0]};
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`;

export default MaxWidthWrapper;
