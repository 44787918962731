import React from 'react';
import { Button } from '@coresine/ui';
import { IButtonProps } from '@blueprintjs/core';
import { theme } from '../../../utils/theming/theming';

const CustomButton: React.FC<IButtonProps> = (props) => {
    return <Button {...props} theme={theme} />;
};

export default CustomButton;
