import { IContactFormFill } from '../../services/contact-service/types';
import { IContactFormSubmitAction } from './types';
import * as ActionTypes from './action.types';

export const submitContactFormAsync = (form: IContactFormFill): IContactFormSubmitAction => {
    return {
        type: ActionTypes.LANDING_PAGE_SUBMIT_CONTACT_FORM_ASYNC,
        payload: {
            form
        }
    };
};

export const submitContactFormSucceeded = (form: IContactFormFill): IContactFormSubmitAction => {
    return {
        type: ActionTypes.LANDING_PAGE_SUBMIT_CONTACT_FORM_SUCCEEDED,
        payload: {
            form
        }
    };
};

export const submitContactFormFailed = (error: Error): IContactFormSubmitAction => {
    return {
        type: ActionTypes.LANDING_PAGE_SUBMIT_CONTACT_FORM_FAILED,
        error
    };
};
