import * as ActionTypes from './action.types';
import * as Types from "./types";
import { CognitoUserPayloadDTO } from '@services/auth';
import { IPanel } from '@blueprintjs/core';

export const setErrorHeight = (errorHeight: number) => {
  return {
    type: ActionTypes.AUTH_SET_ERROR_HEIGHT,
    payload: {
      errorHeight
    }
  }
}

export const setGlobalPanel = (newGlobalPanel: string) => {
  return {
    type: ActionTypes.AUTH_SET_GLOBAL_PANEL,
    payload: {
      newGlobalPanel
    }
  }
}

export const appendPanel = (newPanel: IPanel) => {
  return {
    type: ActionTypes.AUTH_APPEND_PANEL,
    payload: {
      newPanel
    }
  }
}

export const removeLastPanel = () => {
  return {
    type: ActionTypes.AUTH_REMOVE_LAST_PANEL
  }
}

export const setEmail = (email: string) => {
  return {
    type: ActionTypes.AUTH_SET_EMAIL,
    payload: {
      email
    }
  }
}

export const setPassword = (password: string) => {
  return {
    type: ActionTypes.AUTH_SET_PASSWORD,
    payload: {
      password
    }
  }
}


export const loginSucceeded = (user: Types.CognitoUser) => {

  return {
    type: ActionTypes.AUTH_LOGIN_SUCCEEDED,
    payload: {
      user
    }
  }
}

export const loginFailed = (error: Error) => {
  return {
    type: ActionTypes.AUTH_LOGIN_FAILED,
    payload: {
      error
    }
  }
}

export const usernameExistsFailed = (error: Error) => {
  return {
    type: ActionTypes.AUTH_REGISTRATION_USERNAME_CHECK_FAILED,
    payload: {
      error: error
    }
  }
}

export const usernameDoesNotExist = () => {
  return {
    type: ActionTypes.AUTH_REGISTRATION_USERNAME_CHECK_DOES_NOT_EXIST,
    payload: {
      isUsernameTake: false
    }
  }
}

export const usernameAlreadyExists = () => {
  return {
    type: ActionTypes.AUTH_REGISTRATION_USERNAME_CHECK_EXISTS,
    payload: {
      isUsernameTake: true
    }
  }
}

// TODO: Set type to additional info parameter

export const setRegistrationAdditionalInformation = (additionalInformation: Types.IAdditionalInformation) => {
  return {
    type: ActionTypes.AUTH_REGISTRATION_SET_ADDITIONAL_INFORMATION,
    payload: {
      additionalInformation
    }
  }
}


export const registrationSucceeded = (user: Types.CognitoUser) => {
  return {
    type: ActionTypes.AUTH_REGISTRATION_SUCCEEDED,
    payload: {
      user
    }
  }
}

export const registrationFailed = (error: Error) => {
  return {
    type: ActionTypes.AUTH_REGISTRATION_FAILED,
    payload: {
      error
    }
  }
}


export const emailConfirmationSucceeded = (code: string) => {
  return {
    type: ActionTypes.AUTH_CONFIRMATION_SUCCEEDED,
    payload: {
      code
    }
  }
}

export const emailConfirmationFailed = (error: Error) => {
  return {
    type: ActionTypes.AUTH_CONFIRMATION_FAILED,
    payload: {
      error
    }
  }
}

export const resendEmailConfirmationSucceeded = (code: string) => {
  return {
    type: ActionTypes.AUTH_RESEND_CONFIRMATION_SUCCEEDED,
    payload: {
      code
    }
  }
}

export const resendEmailConfirmationFailed = (error: Error) => {
  return {
    type: ActionTypes.AUTH_RESEND_CONFIRMATION_FAILED,
    payload: {
      error
    }
  }
}


export const setCognitoUserPayload = (payload: CognitoUserPayloadDTO) => {
  return {
    type: ActionTypes.AUTH_SET_COGNITO_USER_PAYLOAD,
    payload: {
      payload
    }
  }
}
