import React from 'react';
import { Navbar } from '@blueprintjs/core';
import { css } from '@emotion/core';
import CoresineLogo from '../../public/coresine-logo.svg';
import { useTheme } from 'emotion-theming';
import Theme from '../../utils/theming/theme-type';
import LanguageSelector from '../common/language-selector';
import LandingPageHeaderButtons from './landing-page-header-buttons';
import HomePageHeaderButtons from './home-page-header-buttons';
import Router from 'next/router';
import { authUserChecker, CognitoUserPayloadDTO } from '../../modules/auth';
import { Auth } from '@services/auth';
import AuthButtons from './auth-buttons';

interface IHeader {
    forLandingPage?: boolean;
    forHomePage?: boolean;
    forPartnerPage?: boolean;
}

const Header: React.FunctionComponent<IHeader> = ({
    forLandingPage,
    forHomePage,
    forPartnerPage
}) => {
    const theme: Theme = useTheme();

    React.useEffect(() => {
        authUserChecker(Auth)
            .then((res) => {
                setUser(res);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const [user, setUser] = React.useState<CognitoUserPayloadDTO>();

    const logoOnClickHandler = () => {
        Router.push('/home');
    };

    return (
        <Navbar
            fixedToTop={true}
            className={'flex flex-col justify-center items-center shadow-none h-12'}
            css={css`
                padding-left: ${theme.layout.sidePadding[0]};
                padding-right: ${theme.layout.sidePadding[0]};
                transition: ease-in-out border-bottom 0.4s;
                box-shadow: none;
                border-bottom: 1px solid #e2e8f0;
                > div {
                    height: 3rem;
                }
            `}>
            <header className={'w-full'}>
                <nav className={'flex justify-between'}>
                    <Navbar.Group align={'left'}>
                        <Navbar.Heading>
                            <CoresineLogo
                                onClick={logoOnClickHandler}
                                className={'w-24 h-6 sm:w-32 sm:h-8 cursor-pointer'}
                            />
                        </Navbar.Heading>
                    </Navbar.Group>
                    {forHomePage && (
                        <Navbar.Group
                            className={'sm:relative sm:left-12 sm:flex sm:justify-center'}>
                            {/*<HomePageTabs/>*/}
                        </Navbar.Group>
                    )}
                    <Navbar.Group align={'right'}>
                        <LanguageSelector />
                        <Navbar.Divider
                            className={`hidden ${
                                (forHomePage || forPartnerPage || forLandingPage) && 'md:block'
                            }`}
                        />
                        {forLandingPage && <LandingPageHeaderButtons />}
                        <div className={'hidden md:block'}>
                            {(forHomePage || forPartnerPage) && <HomePageHeaderButtons />}
                        </div>
                        {!user && (
                            <Navbar.Divider
                                className={`hidden ${
                                    (forHomePage || forPartnerPage || forLandingPage) && 'md:block'
                                }`}
                            />
                        )}
                        <AuthButtons auth={user} />
                    </Navbar.Group>
                </nav>
            </header>
        </Navbar>
    );
};

export default Header;
