import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { css } from '@emotion/core';
import { Callout, Collapse, FormGroup, InputGroup, Intent, IPanel, IPanelProps } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import HelperTextOnError from '../common/helper-text-on-error';
import { CurrentPanel } from '../../../protected-route';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../../../actions';
import * as AsyncOperations from '../../../../operations';
import { PASSWORD_PANEL } from '../../registration-panel';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../../../utils/theming/theme-type';
import { ErrorCallout } from '../../../../../../components/common';
import { Button } from '@components/ui';

const PV_REG_EX_SPECIAL_CHARS = /[#?!@$%^&*-]/;
const PV_REG_EX_LOWER_CASE = /[a-z]/;
const PV_REG_EX_UPPER_CASE = /[A-Z]/;
const PV_REG_EX_NUMBER = /[0-9]/;

interface IPasswordPanelProps extends IPanelProps {
  setCurrentPanel: Dispatch<SetStateAction<CurrentPanel>>
  setPasswordHandler: Dispatch<SetStateAction<string>>
  registerHandler: (email: string, password: string) => void
  email: string
  password: string
  error: Error
  user: CognitoUser | undefined
}

interface ICustomListTag {
  text: string | ReactNode
  isValidated: boolean
}

const CustomListTag: React.FunctionComponent<ICustomListTag> = (
  { text, isValidated}) => {

  const theme: Theme = useTheme();

  return (
    <li css={css`
      color: ${ isValidated && theme.colors.success};
    `} >
      {text}
    </li>
  )
}

const PasswordPanel: React.FunctionComponent<IPasswordPanelProps> = (
  { openPanel, setPasswordHandler, email, registerHandler, error, user }) => {

  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const registrationPasswordWatch = watch('registrationPassword');

  React.useEffect(() => {
    asyncAuthStateChecker();
    setIsFetching(false);
  }, [user])

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const submitHandler = (data) => {
    setPasswordHandler(data.registrationPassword);
    setIsFetching(true);
    // TODO Use password state from the redux for consistency and better code quality overall
    registerHandler(email, data.registrationPassword);
  }

  const asyncAuthStateChecker = () => {
    if ( user ) {
      openPanel({
        component: PasswordPanel,
        title: PASSWORD_PANEL
      })
    }
    if ( error ) {
      console.error(error);
    }
  }

  const setPasswordVisibilityHandler = () => {
    setIsPasswordVisible(!isPasswordVisible);
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}
          className={'flex flex-col justify-between h-full m-1'}
          css={css`
            overflow-y: hidden;
          `} >
      <div>
        <FormGroup label={'Password'}
                   labelFor={'password-input'}
                   css={css`
                    padding: 0.2rem;
                   `}
                   helperText={
                     errors?.registrationPassword?.type === 'minLength' ? <HelperTextOnError> The password needs to have 8 characters </HelperTextOnError>
                       :
                       errors?.registrationPassword?.type === 'required' ? <HelperTextOnError> The password field is required </HelperTextOnError>
                         :
                         errors?.registrationPassword?.type === 'validate' ? <HelperTextOnError> The password needs to have at least one uppercase letter, one lowercase letter, one number, one special character </HelperTextOnError>
                           :
                           'Write the password to secure your account'} >
          <InputGroup placeholder={'Eg. $ecure-P@ssw0rd!'}
                      name={'registrationPassword'}
                      intent={errors.registrationPassword ? 'danger' : 'none'}
                      large={true}
                      id={'registration-password'}
                      type={ isPasswordVisible ? 'text' : 'password'}
                      rightElement={ <Button minimal={true}
                                             onClick={setPasswordVisibilityHandler}
                                             intent={ isPasswordVisible ? 'warning' : 'primary'}
                                             icon={ isPasswordVisible ? 'unlock' : 'lock'} /> }
                      inputRef={register({
                        validate: {
                          oneLowercase: value => PV_REG_EX_LOWER_CASE.test(value),
                          oneUppercase: value => PV_REG_EX_UPPER_CASE.test(value),
                          oneNumber: value => PV_REG_EX_NUMBER.test(value),
                          oneSpecial: value => PV_REG_EX_SPECIAL_CHARS.test(value),
                          minLength: value => value.length >= 8
                        },
                        required: true,
                      })} />
        </FormGroup>
      </div>
      <Collapse isOpen={true} >
        <Callout title={'The password must have'}
                 intent={errors?.registrationPassword === undefined && registrationPasswordWatch?.length ? Intent.SUCCESS : Intent.PRIMARY}
                 css={css`
                   margin: 0.5rem 0;
                `} >
          <ul>
            <CustomListTag isValidated={registrationPasswordWatch?.length >= 8} text={'At least 8 characters long'} />
            <CustomListTag isValidated={PV_REG_EX_NUMBER.test(registrationPasswordWatch)} text={'At least one number'} />
            <CustomListTag isValidated={PV_REG_EX_UPPER_CASE.test(registrationPasswordWatch)} text={'At least one uppercase letter'} />
            <CustomListTag isValidated={PV_REG_EX_LOWER_CASE.test(registrationPasswordWatch)} text={'At least one lowercase letter'} />
            <CustomListTag isValidated={PV_REG_EX_SPECIAL_CHARS.test(registrationPasswordWatch)} text={'At least one special character'} />
          </ul>
        </Callout>
      </Collapse>
      <ErrorCallout error={error} />
      <Button type={'submit'}
              intent={'primary'}
              large={true}
              loading={isFetching}
              disabled={errors?.registrationPassword}
              text={'Register'} />
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    authState: state.authState,
    email: state.authState.email,
    password: state.authState.password,
    error: state.authState.error,
    user: state.authState.user
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    appendPanelHandler: (newPanel: IPanel) => dispatch(Actions.appendPanel(newPanel)),
    removeLastPanelHandler: () => dispatch(Actions.removeLastPanel()),
    setPasswordHandler: (password: string) => dispatch(Actions.setPassword(password)),
    registerHandler: (email: string, password: string) => dispatch(AsyncOperations.registrationAsync(email, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPanel);
