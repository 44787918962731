import React, { ReactNode, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Divider, H3, InputGroup } from '@blueprintjs/core';
import { useTheme } from 'emotion-theming';
import Theme from '../utils/theming/theme-type';
import CoresineLogo from '../public/coresine-logo-white.svg';
import { Link, useTranslation } from '../utils/i18n/i18n';
import LinkReplacer from './common/link-replacer';
import { useForm } from 'react-hook-form';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IContactFormFill } from '../services/contact-service/types';
import * as AsyncOperations from '../modules/landing-pages/operations';
import { connect } from 'react-redux';
import { IProps } from '../modules/landing-pages/components/components/contacts/form-block';
import { ToasterShower } from './common';
import { Button } from './ui';

const Footer: React.FunctionComponent<IProps> = ({ onFormSubmitPressed, form }) => {
    const { register, handleSubmit } = useForm();
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        if (form?.submitted) {
            emailSubmissionSucceeded();
        }
        if (!form?.submitted && isJoinButtonPressed) {
            emailSubmissionFailed();
        }
    }, [form]);

    const [isJoinButtonPressed, setIsJoinButtonPressed] = useState<boolean>(false);

    const emailSubmitHandler = (email: any) => {
        const newData = {
            name: 'FOOTER',
            ...email,
            phone: 'FOOTER',
            text: 'FOOTER'
        };
        console.log('newData: ', newData);
        setIsJoinButtonPressed(true);
        onFormSubmitPressed(newData);
    };

    const emailSubmissionSucceeded = () => {
        ToasterShower.show({
            message: t('landing-page:message.message-page-toaster-success'),
            intent: 'success'
        });
    };

    const emailSubmissionFailed = () => {
        ToasterShower.show({
            message: t('landing-page:message.message-page-toaster-failure'),
            intent: 'danger'
        });
    };

    const linksSection: ReactNode = (
        <section
            css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 4rem;
                margin-bottom: 2rem;
                a,
                h3 {
                    color: white;
                }
                a {
                    font-size: ${theme.fontSize.tertiary};
                }
                h3 {
                    font-size: ${theme.fontSize.header};
                }
                ${theme.layout.mq[0]} {
                    margin-top: 0rem;
                    justify-content: flex-start;
                    h3 {
                        font-size: ${theme.fontSize.header};
                    }
                }
                ${theme.layout.mq[1]} {
                    a {
                        font-size: ${theme.fontSize.tertiary};
                    }
                    h3 {
                        font-size: ${theme.fontSize.header};
                    }
                }
            `}>
            <nav
                css={css`
                    display: flex;
                    flex-direction: column;
                    a {
                        font-size: ${theme.fontSize.default};
                        margin-top: 0.5rem;
                    }
                `}>
                <H3> {t('footer:social-media-header')} </H3>
                <a target={'_blank'} href={'https://www.facebook.com/coresine/'}>
                    {' '}
                    Facebook{' '}
                </a>
                <a target={'_blank'} href={'https://www.linkedin.com/company/51022730/'}>
                    {' '}
                    LinkedIn{' '}
                </a>
            </nav>
            <nav
                css={css`
                    display: flex;
                    flex-direction: column;
                    margin-left: 1.25rem;
                    a {
                        margin-top: 0.5rem;
                    }
                    ${theme.layout.mq[0]} {
                        margin-left: 5vw;
                    }
                    ${theme.layout.mq[1]} {
                        margin-left: 10vw;
                    }
                `}>
                <H3> {t('footer:navigation-header')} </H3>
                <LinkReplacer url={'/for-customers'} text={t('footer:for-customers-page-link')} />
                <LinkReplacer url={'/for-partners'} text={t('footer:for-partners-page-link')} />
                <LinkReplacer url={'/about-us'} text={t('footer:about-us-page-link')} />
                <LinkReplacer url={'/contacts'} text={t('footer:contacts-page-link')} />
            </nav>
        </section>
    );

    const reachOutSection: ReactNode = (
        <section
            css={css`
                display: flex;
                flex-direction: column;
                a,
                h3 {
                    color: white;
                }
                h3 {
                    margin: 1rem 0;
                    font-size: ${theme.fontSize.header};
                }
                ${theme.layout.mq[0]} {
                    h3 {
                        font-size: ${theme.fontSize.header};
                    }
                }
                ${theme.layout.mq[1]} {
                    h3 {
                        font-size: ${theme.fontSize.header};
                    }
                }
            `}>
            <Link href={'/'}>
                <CoresineLogo
                    css={css`
                        width: 13rem;
                        :hover {
                            cursor: pointer;
                        }
                    `}
                />
            </Link>
            {/* TODO The Finnish version of let-us-reach-out-to-you was translated by Google Translate, this should be fixed */}
            <H3> {t('footer:let-us-reach-out')} </H3>
            <form
                onSubmit={handleSubmit(emailSubmitHandler)}
                css={css`
                    display: flex;
                    flex-direction: row;
                    button {
                        margin-left: 1.25rem;
                    }
                `}>
                <InputGroup
                    type={'email'}
                    large={false}
                    placeholder={'example@example.com'}
                    name={'email'}
                    inputRef={register({ required: true })}
                    disabled={form?.submitted}
                />
                <Button
                    text={form?.submitted ? 'Sent' : 'Send'}
                    large={false}
                    type={'submit'}
                    loading={isJoinButtonPressed && !form?.submitted}
                    disabled={form?.submitted}
                    intent={form?.submitted ? 'success' : 'primary'}
                    css={css`
                        background-color: white !important;
                    `}
                    outlined={true}
                />
            </form>
            <Divider
                css={css`
                    background-color: white;
                    margin-top: 1rem;
                `}
            />
            <H3> {t('footer:reach-out-header')} </H3>
            <a
                href={'mailto:nursultan@coresine.com'}
                className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                <u>@coresine</u>
            </a>
        </section>
    );

    return (
        <footer
            css={css`
                position: static;
                bottom: 0;
                right: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 3rem;
                padding-left: ${theme.layout.sidePadding[0]};
                padding-right: ${theme.layout.sidePadding[0]};
                background-color: #4185f4;
                color: white;
            `}>
            <div
                css={css`
                    max-width: 1024px;
                    width: 100%;
                `}>
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        height: 70vh;
                        min-height: 34rem;
                        ${theme.layout.mq[0]} {
                            flex-direction: row;
                            height: 60vh;
                            min-height: 28rem;
                        }
                        ${theme.layout.mq[1]} {
                            flex-direction: row;
                            height: 30vh;
                            min-height: 23rem;
                        }
                    `}>
                    {reachOutSection}
                    {linksSection}
                </div>
                <p
                    css={css`
                        text-align: center;
                    `}>
                    &copy;Coresine 2020
                </p>
            </div>
        </footer>
    );
};

const mapStateToProps = (state) => {
    return {
        form: state.landingPageState.form
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onFormSubmitPressed: (form: IContactFormFill) =>
            dispatch(AsyncOperations.submitContactFormAsync(form))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
