import { isBrowser } from '@unly/utils';
import { IAnalyticsEvent } from './types';
import { AmplitudeClient } from 'amplitude-js';

export class AmplitudeAnalyticsClient {
    public static getClient = () => {
        if (isBrowser()) {
            const amplitude = require('amplitude-js'); // eslint-disable-line @typescript-eslint/no-var-requires
            const instance: AmplitudeClient = amplitude.getInstance();
            instance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
            return instance;
        } else {
            return null;
        }
    };
    public static logEvent = async (data: IAnalyticsEvent, client: AmplitudeClient | null) => {
        if (client) {
            client.logEvent(data.action, {
                ...data,
                release: process.env.NEXT_PUBLIC_APP_VERSION
                    ? process.env.NEXT_PUBLIC_APP_VERSION
                    : '',
                branch: process.env.BRANCH_NAME ? process.env.BRANCH_NAME : '',
                stage: process.env.NEXT_PUBLIC_APP_STAGE
            });
        }
    };
}
