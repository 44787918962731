import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/core';
import { FormGroup, InputGroup, IPanelProps } from '@blueprintjs/core';
import PasswordResetSuccessPanel from './password-reset-success-panel';
import { Button } from '@components/ui';

const PasswordResetPanel: React.FunctionComponent<IPanelProps> = ({openPanel}) => {

  const [isLoading, ] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const { register, handleSubmit } = useForm();

  const onSubmitHandler = () => {
    // setIsLoading(true);
    /**
     * Here goes a call to the passed Redux action
     */
    openPanel({
      component: PasswordResetSuccessPanel,
      title: 'Success!'
    })
  }

  const setPasswordVisibilityHandler = () => {
    setIsPasswordVisible(!isPasswordVisible);
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}
          className={'flex flex-col justify-between h-full m-1'}>
      <FormGroup label={'New Password'}
                 labelFor={'newPassword'}
                 helperText={'Type in your new password here'} >
        <InputGroup placeholder={'Eg. str0ng3r_p@assvv0rd'}
                    name={'newPassword'}
                    large={true}
                    id={'newPassword'}
                    type={ isPasswordVisible ? 'text' : 'password'}
                    rightElement={ <Button minimal={true}
                                           onClick={setPasswordVisibilityHandler}
                                           intent={ isPasswordVisible ? 'warning' : 'primary'}
                                           icon={ isPasswordVisible ? 'unlock' : 'lock'} /> }
                    inputRef={register()} />
      </FormGroup>
      <Button type={'submit'}
              intent={'primary'}
              large={true}
              fill={true}
              loading={isLoading}
              text={'Change the password'}
              css={css`

              `} />
    </form>
  );
};

export default PasswordResetPanel;
