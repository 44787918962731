import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useForm } from 'react-hook-form';
import { Checkbox, H5 } from '@blueprintjs/core';
import { useTranslation } from '../../utils/i18n/i18n';
import UniversalCookiesManager from '../../utils/cookies/UniversalCookiesManager';
import { ToasterShower } from '@components';
import {
    ANALYTICS_PERMISSION,
    BASKET_PERMISSION,
    LANGUAGE_PERMISSION
} from '../../utils/cookies/constants';
import { Button } from '@components/ui';

interface ICookieSettings {
    cookieSettingsRemoveHandler: any;
    setShowCookieBanner: any;
}

const CookieSettings: React.FunctionComponent<ICookieSettings> = ({
    cookieSettingsRemoveHandler,
    setShowCookieBanner
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();

    const universalCookeManager = new UniversalCookiesManager();

    const onSubmit = (data) => {
        universalCookeManager.setAcceptedCookies(data);
        setIsLoading(true);
        cookiesChangeChecker();
        cookieSettingsRemoveHandler();
        setShowCookieBanner(false);
    };

    const cookiesChangeChecker = () => {
        setIsLoading(false);
        ToasterShower.show({ message: 'Cookies settings were updated!', intent: 'success' });
    };

    return (
        <div
            css={css`
                width: 100%;
                height: 100%;
                background-color: white;
                padding: 0 1.25rem;
            `}>
            <section>
                <p>{t('common:cookie-settings.description')}</p>
                <p>
                    <strong> {t('common:cookie-settings.compulsory-cookies-label')} </strong>
                    {t('common:cookie-settings.compulsory-cookies-description')}
                </p>
            </section>
            <form
                onSubmit={handleSubmit(onSubmit)}
                css={css`
                    display: flex;
                    flex-direction: column;
                `}>
                <section
                    css={css`
                        display: flex;
                        flex-direction: row;
                    `}>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            margin-right: 3rem;
                        `}>
                        <H5> Compulsory cookies </H5>
                        <Checkbox
                            label={t('common:cookie-settings.language-title')}
                            inputRef={register}
                            name={LANGUAGE_PERMISSION}
                            checked={true}
                            disabled={true}
                        />
                        <Checkbox
                            label={t('common:cookie-settings.basket-title')}
                            name={BASKET_PERMISSION}
                            inputRef={register}
                            checked={true}
                            disabled={true}
                        />
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                        `}>
                        <H5> Additional cookies </H5>
                        <Checkbox
                            label={t('common:cookie-settings.analytics-title')}
                            inputRef={register}
                            checked={true}
                            name={ANALYTICS_PERMISSION}
                        />
                    </div>
                </section>
                <Button
                    intent={'primary'}
                    type={'submit'}
                    text={t('common:cookie-settings.submit-button')}
                    loading={isLoading}
                    alignText={'center'}
                />
            </form>
        </div>
    );
};

export default CookieSettings;
