import React from 'react';
import { Popover, Menu, Position, Intent, Tooltip, ButtonGroup } from '@blueprintjs/core';
import { logout } from '@services/auth/client';
import Router from 'next/router';
import { ToasterShower } from '../../index';
import { Button } from '@components/ui';
import { IconNames } from '@blueprintjs/icons';

interface IAccountButton {
    isMobile: boolean;
}

const AccountButton: React.FC<IAccountButton> = ({ isMobile }) => {
    const logOutHandler = (): void => {
        logout()
            .then(() => {
                Router.push('/');
            })
            .catch((error) => {
                console.error(error);
                ToasterShower.show({
                    message:
                        'An error happened and we could not logout from this account, we are sorry',
                    intent: Intent.DANGER
                });
            });
    };

    if (isMobile) {
        return (
            <ButtonGroup vertical={true} fill={true} alignText={'left'} large={true} minimal={true}>
                <Button
                    icon={IconNames.USER}
                    text={'Profile'}
                    fill={true}
                    disabled={true}
                    large={true}
                    minimal={true}
                    intent={Intent.PRIMARY}
                />
                {/*<Button icon={IconNames.LOG_OUT}*/}
                {/*        onClick={logOutHandler}*/}
                {/*        minimal={true}*/}
                {/*        fill={true}*/}
                {/*        intent={Intent.DANGER}*/}
                {/*        text={'Logout'} />*/}
            </ButtonGroup>
        );
    }
    if (!isMobile) {
        return (
            <Popover
                content={
                    <Menu>
                        {/*<Menu.Item text={'Settings'} icon={'cog'} intent={Intent.PRIMARY} />*/}
                        {/*<Menu.Divider />*/}
                        <Menu.Item
                            text={'Logout'}
                            icon={IconNames.LOG_OUT}
                            intent={Intent.DANGER}
                            onClick={logOutHandler}
                        />
                    </Menu>
                }>
                <Tooltip
                    content={'Account'}
                    intent={Intent.PRIMARY}
                    lazy={true}
                    position={Position.BOTTOM}>
                    <Button
                        icon={IconNames.USER}
                        text={'Profile'}
                        fill={true}
                        large={true}
                        minimal={true}
                        intent={Intent.PRIMARY}
                    />
                </Tooltip>
            </Popover>
        );
    }
};

export default AccountButton;
