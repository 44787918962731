import { css } from '@emotion/core';
import 'react';
import { Card, IPanel, PanelStack } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../utils/theming/theme-type';
import EmailPanel from './components/forgot-password-form/email-panel';

const ForgotPasswordPanel: React.FunctionComponent = () => {
    const theme: Theme = useTheme();

    const [currentPanelStack, setCurrentPanelStack] = useState<IPanel[]>([
        { component: EmailPanel, title: 'Email' }
    ]);

    const onOpenHandler = (newPanel: IPanel) => {
        setCurrentPanelStack([...currentPanelStack, newPanel]);
    };

    const onCloseHandler = () => {
        setCurrentPanelStack(currentPanelStack.slice(0, -1));
    };

    return (
        <main
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                height: 100vh;
            `}>
            <Card
                css={css`
                    padding: 0.5rem;
                    ${theme.layout.mq[1]} {
                        padding: 1.2rem;
                    }
                `}>
                <PanelStack
                    renderActivePanelOnly={false}
                    onClose={onCloseHandler}
                    onOpen={onOpenHandler}
                    stack={currentPanelStack}
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 17rem;
                        height: 20rem;
                        .bp3-panel-stack-header {
                            box-shadow: none;
                            margin-bottom: 2.5rem;

                            .bp3-heading {
                                font-size: ${theme.fontSize.header};
                            }
                        }
                        .bp3-panel-stack-view {
                            border-right: 0;
                        }
                        ${theme.layout.mq[0]} {
                            width: 25rem;
                            height: 16rem;
                        }
                        ${theme.layout.mq[1]} {
                            width: 30rem;
                        }
                    `}
                />
            </Card>
        </main>
    );
};

export default ForgotPasswordPanel;
