import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';

const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

// Check that `window` is in scope for the analytics module
if (typeof window !== 'undefined' && !firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.performance();
    // To enable analytics. https://firebase.google.com/docs/analytics/get-started
    if ('measurementId' in config) firebase.analytics();
}

export default firebase;
