import React from 'react';
import styled from '@emotion/styled';

interface Props {
    padding: string;
}

const HorizontallyScrollableOneRowGrid = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;
    padding: ${(props: Props) => props.padding};
    overflow-x: scroll;
    // scrollbar-width: none;
    // :-webkit-scrollbar {
    //     display: none;
    // }
`;

export default HorizontallyScrollableOneRowGrid;
