import React from 'react';
import { Button } from '@components/ui';
import { Intent } from '@blueprintjs/core';
import { CognitoUserPayloadDTO } from '@services/auth';
import Router from 'next/router';
import { AccountButton } from './components';
import useMedia from 'use-media';

interface IAuthButtons {
    auth: CognitoUserPayloadDTO;
}

const AuthButtons: React.FC<IAuthButtons> = ({ auth }) => {
    const isMobile: boolean = useMedia({ maxWidth: 768 });

    const loginHandler = (): void => {
        Router.push('auth');
    };

    const registerHandler = (): void => {
        Router.push('auth');
    };

    if (!auth) {
        return (
            <span className={'flex'}>
                <Button
                    intent={Intent.PRIMARY}
                    minimal={true}
                    text={'Login'}
                    className={'mr-2'}
                    onClick={loginHandler}
                />
                <Button
                    intent={Intent.PRIMARY}
                    text={'Register'}
                    minimal={false}
                    onClick={registerHandler}
                />
            </span>
        );
    }
    if (auth) {
        return <AccountButton isMobile={isMobile} />;
    }
};

export default AuthButtons;
