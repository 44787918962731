import React from 'react';
import { Button, H4, Icon } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

const PasswordResetSuccessPanel: React.FunctionComponent = () => {
    const onButtonClickHandler = () => {};

    return (
        <section
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            `}>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}>
                <H4> Password was successfully changed! </H4>
                <Icon
                    icon={'tick-circle'}
                    intent={'success'}
                    iconSize={80}
                    css={css`
                        margin-top: 1rem;
                    `}
                />
            </div>
            <Button
                intent={'primary'}
                onClick={onButtonClickHandler}
                large={true}
                text={'Login'}
                css={css``}
            />
        </section>
    );
};

export default PasswordResetSuccessPanel;
