import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Gallery from 'react-photo-gallery';
import Image from 'next/image';
import { css } from '@emotion/core';
import { IImage } from '@interfaces/partner';
import { Button } from '@components/ui';
import { Intent } from '@blueprintjs/core';

interface IImagesGrid {
    photos: any[];
}

/**
 * This component uses react-images and react-photo-library libraries to
 * render an images carousel, in modal and on the page and render a grid of images
 * @param photos {IImage} - an object of photos with a URL to the photo and its dimensions
 */
const ImagesGrid: React.FC<IImagesGrid> = ({ photos }) => {
    const [viewerIsOpen, setViewerIsOpen] = React.useState<boolean>(false);
    const [currentImage, setCurrentImage] = React.useState<number>(0);

    const closeLightbox = (): void => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const openLightbox = (index): void => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const imageRenderer = ({ photo, index }) => {
        const onClickHandler = () => {
            openLightbox(index);
        };

        return (
            <Image
                src={photo.src}
                css={css`
                    cursor: pointer;
                    margin: 0.25rem !important;
                `}
                onClick={onClickHandler}
                width={photo.width}
                height={photo.height}
            />
        );
    };

    return (
        <>
            <Gallery
                photos={viewerIsOpen ? photos : photos.slice(0, 3)}
                renderImage={imageRenderer}
                direction={'row'}
            />
            <Button
                text={`Show all ${photos.length} pictures`}
                minimal={true}
                onClick={() => setViewerIsOpen(true)}
                className={'mt-2'}
                fill={true}
                intent={Intent.PRIMARY}
            />
            {/* This piece of code was copied from the examples of the library -
          http://neptunian.github.io/react-photo-gallery/examples/lightbox.html */}
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map((x: any) => ({
                                ...x
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    );
};

export default ImagesGrid;
