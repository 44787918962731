import Theme from '../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { Router } from '../../utils/i18n/i18n';
import { css } from '@emotion/core';

interface ILinkReplacer {
    url: string;
    text: string;
    color?: string;
}

const LinkReplacer = (props: ILinkReplacer) => {
    const theme: Theme = useTheme();
    return (
        <span
            className={'text-blue-500 hover:text-blue-800 hover:underline'}
            // @ts-ignore
            onClick={() => Router.push(props.url).then(window.scrollTo(0, 0))}
            css={css`
                color: ${props.color ? props.color : 'white'};
                font-size: ${theme.fontSize.default};
                margin-top: 0.5rem;
                :hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
                ${theme.layout.mq[1]} {
                    font-size: ${theme.fontSize.default};
                }
            `}>
            {props.text}
        </span>
    );
};

export default LinkReplacer;
