import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormGroup, InputGroup, Intent, IPanelProps } from '@blueprintjs/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CurrentPanel } from '../../../protected-route';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as Actions from "../../../../actions";
import { ErrorCallout } from '../../../../../../components/common';
import HelperTextOnError from '../common/helper-text-on-error';
import * as ActionCreators from '../../../../actions';
import * as AsyncOperations from '../../../../operations';
import { Status } from '../../../../types';
import { Router } from '../../../../../../utils/i18n/i18n';
import { Button } from '@components/ui';

interface IEmailPanelProps extends IPanelProps {
  setErrorHeight: Dispatch<SetStateAction<number>>;
  loginHandler: (email: string, password: string) => void
  setEmailHandler: Dispatch<SetStateAction<string>>;
  setPasswordHandler: Dispatch<SetStateAction<string>>;
  setNewGlobalPanelHandler: Dispatch<CurrentPanel>;
  status: Status;
  error: Error;
}

const EmailPasswordPanel: React.FunctionComponent<IEmailPanelProps> = (
  { loginHandler,
    setErrorHeight,
    setEmailHandler,
    setPasswordHandler,
    status,
    error,
    setNewGlobalPanelHandler }) => {

  const { register, handleSubmit, errors } = useForm();

  React.useEffect(() => {
    console.log('window.location.pathname: ', window.location.pathname);
    if ( status === 'authenticated' ) {
      if ( window.location.pathname === '/auth' ) {
        Router.push('/home');
      } else {
        document.location.reload();
      }
      setIsLoading(false);
    }
    if ( error ) {
      setIsLoading(false);
    }
  }, [status, error]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const onSubmitHandler: SubmitHandler<Record<string, any>> = (data) => {
    setIsLoading(true);
    setEmailHandler(data.email);
    setPasswordHandler(data.password);
    loginHandler(data.email, data.password);
  }

  const setPasswordVisibilityHandler = () => {
    setIsPasswordVisible(!isPasswordVisible);
  }

  const setCurrentPanelHandler = (): void => {
    setNewGlobalPanelHandler('registration');
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}
          className={'flex flex-col justify-between h-full m-1'}>
      <div>
        <FormGroup label={'Email'}
                   labelFor={'email'}
                   helperText={'Email, username or phone number'} >
          <InputGroup placeholder={'Eg. john@doe.com'}
                      name={'email'}
                      type={'text'}
                      id={'email'}
                      large={true}
                      inputRef={register({
                        required: true
                      })} />
        </FormGroup>
        <FormGroup label={'Password'}
                   labelFor={'password'}
                   helperText={
                     errors?.registrationPassword?.type === 'required' ?
                       <HelperTextOnError> The password field is required </HelperTextOnError>
                       :
                       'Password for this account' } >
          <InputGroup placeholder={'E.g. str0ng_p@ssw0rd'}
                      name={'password'}
                      id={'password'}
                      large={true}
                      intent={errors?.password ? Intent.DANGER : 'none'}
                      type={ isPasswordVisible ? 'text' : 'password'}
                      rightElement={ <Button minimal={true}
                                             onClick={setPasswordVisibilityHandler}
                                             intent={ isPasswordVisible ? 'warning' : 'primary'}
                                             icon={ isPasswordVisible ? 'unlock' : 'lock'} /> }
                      inputRef={register({
                        required: true
                      })} />
        </FormGroup>
        <a onClick={setCurrentPanelHandler} > Do not have an account? </a>
      </div>
      <ErrorCallout error={error} setErrorHeight={setErrorHeight} />
      <Button type={'submit'}
              intent={Intent.PRIMARY}
              large={true}
              fill={true}
              loading={isLoading}
              text={'Login'} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.authState.loginError,
    status: state.authState.status
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setErrorHeight: (height: number) => dispatch(Actions.setErrorHeight(height)),
    setEmailHandler: (email: string) => dispatch(Actions.setEmail(email)),
    setNewGlobalPanelHandler: (newGlobalPanel: string) => dispatch(Actions.setGlobalPanel(newGlobalPanel)),
    setPasswordHandler: (password: string) => dispatch(ActionCreators.setPassword(password)),
    loginHandler: (email: string, password: string) => dispatch(AsyncOperations.loginAsync(email, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailPasswordPanel);
