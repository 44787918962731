import Theme from './theme-type';

type breakpointsType = 640 | 768 | 900 | 1024 | 1440 | 2560;

const breakpoints: breakpointsType[] = [640, 768, 900, 1024, 1440, 2560];
const mq: string[] = breakpoints.map((bp: breakpointsType) => `@media (min-width: ${bp}px)`);

const theme: Theme = {
    colors: {
        primary: '#4185F4',
        primaryHover: '#3767B1',
        primaryMinimalHover: '#E2EDFE',
        complementary: ['#f45c41', '#f4b541'],
        grey: '#E2E8F0',
        greyPrimaryTint: '#F2F8FF',
        secondary: '#333333',
        tertiary: '#6F6F6F',
        warning: '#FF914D',
        warningHover: '#b86a3a',
        warningMinimalHover: '#FFEFE4',
        danger: '#ED1212',
        dangerHover: '#AD1010',
        dangerMinimalHover: '#FCDBDB',
        success: '#29BF12',
        successHover: '#1D920E',
        successMinimalHover: '#DFF5DB'
    },
    fontSize: {
        display: '2.5rem',
        header: '1.6rem',
        default: '1rem',
        secondary: '0.9rem',
        tertiary: '0.7rem'
    },
    layout: {
        sidePadding: ['5%', '10%'],
        mq: mq
    }
};

export { theme, mq };
