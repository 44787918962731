import React, { Dispatch, useState } from 'react';
import { FormGroup, InputGroup, IPanelProps } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import PasswordResetPanel from './password-reset-panel';
import { CurrentPanel } from '../../../protected-route';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as Actions from '../../../../actions';
import { connect } from 'react-redux';
import { Button } from '@components/ui';

interface IEmailPanel extends IPanelProps {
  setNewGlobalPanelHandler: Dispatch<CurrentPanel>
}

const EmailPanel: React.FunctionComponent<IEmailPanel> = ({openPanel, setNewGlobalPanelHandler}) => {

  const [isLoading, ] = useState<boolean>(false);
  const { register, handleSubmit } = useForm();

  const onSubmitHandler = () => {
    /**
     * Here goes a call to the passed Redux action
     */
    openPanel({
      component: PasswordResetPanel,
      title: 'New Password'
    })
  }

  const setCurrentPanelHandler = () => {
    setNewGlobalPanelHandler('login')
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}
          className={'flex flex-col justify-between h-full m-1'} >
      <FormGroup label={'Email'}
                 labelFor={'email'}
                 helperText={'Type in the email of the account you forgot your password of'} >
        <InputGroup placeholder={'john@doe.com'}
                    name={'email'}
                    large={true}
                    id={'email'}
                    inputRef={register()} />
      </FormGroup>
      <a onClick={setCurrentPanelHandler} > I remember my password </a>
      <Button type={'submit'}
              intent={'primary'}
              large={true}
              fill={true}
              loading={isLoading}
              text={'Reset my password'} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    currentGlobalAuthPanel: state.authState.props.currentGlobalAuthPanel
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setNewGlobalPanelHandler: (newGlobalPanel: string) => dispatch(Actions.setGlobalPanel(newGlobalPanel))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailPanel);
