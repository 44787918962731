import React, { useState } from 'react';
import { Button } from '@components/ui';
import { Divider, Drawer, Intent } from '@blueprintjs/core';
import LinksComponent from '../links-component';
import { IconNames } from '@blueprintjs/icons';
import AuthButtons from '../auth-buttons';
import { Auth, CognitoUserPayloadDTO } from '@services/auth';
import { authUserChecker } from '../../../modules/auth';
import HomePageHeaderButtons from '../home-page-header-buttons';
import { css } from '@emotion/core';
import { logout } from '@services/auth/client';
import Router from 'next/router';
import { ToasterShower } from '@components';

interface IDrawerMenu {
    isLandingPage?: boolean;
    isHomePage?: boolean;
    user?: CognitoUserPayloadDTO;
}

const DrawerMenu: React.FC<IDrawerMenu> = ({ isLandingPage, isHomePage }) => {
    const [isDrawerMenuOpen, setIsDrawerMenuOpen] = useState<boolean>(false);
    const [user, setUser] = React.useState<CognitoUserPayloadDTO>();

    React.useEffect(() => {
        authUserChecker(Auth)
            .then((res) => {
                setUser(res);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const logOutHandler = (): void => {
        logout()
            .then(() => {
                Router.push('/');
            })
            .catch((error) => {
                console.error(error);
                ToasterShower.show({
                    message:
                        'An error happened and we could not logout from this account, we are sorry',
                    intent: Intent.DANGER
                });
            });
    };

    const drawerMenuOpener = (): void => {
        setIsDrawerMenuOpen(true);
    };

    const drawerMenuCloser = (): void => {
        setIsDrawerMenuOpen(false);
    };

    return (
        <div className={'flex flex-col md:hidden'}>
            <Button
                icon={IconNames.MENU_CLOSED}
                minimal={true}
                onClick={drawerMenuOpener}
                intent={Intent.PRIMARY}
            />
            <Drawer
                isOpen={isDrawerMenuOpen}
                canOutsideClickClose={true}
                canEscapeKeyClose={true}
                isCloseButtonShown={false}
                title={<AuthButtons auth={user} />}
                position={'right'}
                className={'p-2'}
                size={'240px'}
                usePortal={true}
                onClose={drawerMenuCloser}
                css={css`
                    .bp3-drawer-header {
                        padding-left: 5px;
                    }
                `}>
                <section className={'mt-2'}>
                    {isHomePage && <HomePageHeaderButtons />}
                    {isLandingPage && <LinksComponent orientation={'vertical'} />}
                </section>
                {user && (
                    <section
                        css={css`
                            position: absolute;
                            bottom: 0.5rem;
                            width: 100%;
                            padding-right: 1rem;
                        `}>
                        <Divider />
                        <Button
                            icon={IconNames.LOG_OUT}
                            onClick={logOutHandler}
                            minimal={true}
                            fill={true}
                            intent={Intent.DANGER}
                            text={'Logout'}
                        />
                    </section>
                )}
            </Drawer>
        </div>
    );
};

export default DrawerMenu;
