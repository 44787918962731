import React from 'react';
import { NotificationsButton, ShareableListsButton } from './components';

const HomePageHeaderButtons: React.FC = () => {
    return (
        <nav className={'flex flex-col md:flex-row'}>
            <ShareableListsButton />
            <NotificationsButton />
            {/*<SettingsButton />*/}
        </nav>
    );
};

export default HomePageHeaderButtons;
