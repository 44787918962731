import { Auth } from 'aws-amplify';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import cryptoRandomString from 'crypto-random-string';

export interface IUserRegister {
    email: string;
    password: string;
}

export const register = ({ email, password }: IUserRegister): Promise<ISignUpResult> => {
    console.log(email, password);
    return new Promise(async (resolve, reject) => {
        try {
            const date = Date.now();
            const result = await Auth.signUp({
                username: `${date.toString()}-${cryptoRandomString({
                    length: 10,
                    type: 'url-safe'
                })}`, // A hack: using random username
                password,
                attributes: {
                    // 'custom:isBusiness': 'false',
                    'custom:has_active_partners': 'false',
                    email
                }
            });

            return resolve(result);
        } catch (error) {
            return reject(error);
        }
    });
};

export interface IConfirmRegisterInput {
    email: string;
    code: string;
}

export const confirmRegister = ({ email, code }: IConfirmRegisterInput) => {
    return new Promise(async (resolve, reject) => {
        try {
            const user = await Auth.confirmSignUp(email, code);
            return resolve(user);
        } catch (error) {
            return reject(error);
        }
    });
};

export interface ILoginInput {
    email: string;
    password: string;
}

export const login = ({ email, password }: ILoginInput) => {
    return new Promise(async (resolve, reject) => {
        try {
            const user = await Auth.signIn(email, password);
            resolve(user);
            // if (user.challengeName === 'SMS_MFA' ||
            //     user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            //
            // } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            //
            // } else if (user.challengeName === 'MFA_SETUP') {
            //     Auth.setupTOTP(user);
            // } else {
            //
            // }
        } catch (err) {
            return reject(err);
            // console.log('error signing in', err);
            // if (err.code === 'UserNotConfirmedException') {
            //     // The error happens if the user didn't finish the confirmation step when signing up
            //     // In this case you need to resend the code and confirm the user
            //     // About how to resend the code and confirm the user, please check the signUp part
            // } else if (err.code === 'PasswordResetRequiredException') {
            //     // The error happens when the password is reset in the Cognito console
            //     // In this case you need to call forgotPassword to reset the password
            //     // Please check the Forgot Password part.
            // } else if (err.code === 'NotAuthorizedException') {
            //     // The error happens when the incorrect password is provided
            // } else if (err.code === 'UserNotFoundException') {
            //     // The error happens when the supplied username/email does not exist in the Cognito user pool
            // } else {
            //     console.log(err);
            // }
        }
    });
};

export interface IResendConfirmationInput {
    email: string;
}

export const resendConfirmationCode = (email: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await Auth.resendSignUp(email);
            return resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const logout = ({ global }: { global: boolean } = { global: false }) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await Auth.signOut({ global }));
        } catch (err) {
            reject(err);
        }
    });
};

export const checkIfUsernameExists = (username: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const user = await login({ password: 'Qwerty12!', email: username });
        } catch (err) {
            return reject(err);
        }
    });
};

export const checkIfEMailExists = (email: string) => {
    return fetch(
        `https://elzy6a9c58.execute-api.eu-central-1.amazonaws.com/dev/checkIfUsernameEmailExists?email=${email}`
    );
};
// export type { IRegisterBusinessInput, IConfirmRegisterInput, ILoginInput, IResendConfirmationInput };
