import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../utils/theming/theming';

const BadgeWrapper = styled.div`
    > button {
        ::after {
            position: relative;
            top: -0.4rem;
            right: 0;
            content: '${(props) => props['value'].toString()}';
            font-size: ${theme.fontSize.default};
            text-align: center;
            line-height: 1rem;
            color: white;
            background-color: ${theme.colors.warning};
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            transition: all ease-in-out 0.2s;
        }
    }
`;

export default BadgeWrapper;
