import React from 'react';
import Image from 'next/image';

interface IProgressiveImage {
    src: any;
    alt?: string;
    width?: number;
    height?: number;
    className?: string;
}

/**
 * ProgressiveImage - Loads only when in viewport. It first loads placeholder and then the image via transition effect.
 * @param className CSS class to apply to the image
 * @param src Source of the image
 * @param width Width of the image
 * @param height Height of the image
 * @param alt Alt text for the image
 * @param partialVisibility If set to true, images load even if a bit of them is in the viewport
 * @constructor
 */
const ProgressiveImage: React.FunctionComponent<IProgressiveImage> = ({
    src,
    alt,
    width,
    height,
    className
}) => {
    return (
        <Image
            className={className}
            src={src || '/no-image.jpg'}
            alt={alt || 'Partner Image'}
            width={width || 1050}
            height={height || 1050}
            quality={100}
        />
    );
};

export default ProgressiveImage;
