import { I18n } from 'aws-amplify';
import Link from 'next/link';
import Router from 'next/router';

export { Link };
export { Router };
export const i18n = I18n;
export const useTranslation = () => {
    return {
        t: (key: string): string => {
            return I18n.get(key, key);
        }
    };
};
