import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { css } from '@emotion/core';

const CookieDocument: React.FunctionComponent = () => {
    return (
        <div
            className={'p-4 overflow-y-auto'}
            css={css`
                width: 35rem;
                height: 24rem;
            `}>
            <h4 className={'text-lg font-bold mb-4'}> PRIVACY NOTICE </h4>

            <span className={'italic text-md mb-2'}> Last updated November 08, 2020 </span>
            <p className={'my-2'}>
                Thank you for choosing to be part of our community at Coresine Oy ("
                <strong>Company</strong>", "<strong>we</strong>", "<strong>us</strong>", "{' '}
                <strong>our</strong>"). We are committed to protecting your personal information and
                your right to privacy. If you have any questions or concerns about this privacy
                notice, or our practices with regards to your personal information, please contact
                us at nursultan@coresine.com.
            </p>
            <p className={'my-2'}>
                When you visit our website https://www.coresine.com/fi (the "
                <strong>Website</strong>"), and more generally, use any of our services (the
                "Services", which include the Website), we appreciate that you are trusting us with
                your personal information. We take your privacy very seriously. In this privacy
                notice, we seek to explain to you in the clearest way possible what information we
                collect, how we use it and what rights you have in relation to it. We hope you take
                some time to read through it carefully, as it is important. If there are any terms
                in this privacy notice that you do not agree with, please discontinue use of our
                Services immediately.
            </p>
            <p className={'my-2'}>
                This privacy notice applies to all information collected through our Services
                (which, as described above, includes our Website), as well as, any related services,
                sales, marketing or events.
            </p>
            <p className={'my-2'}>
                Please read this privacy notice carefully as it will help you understand what we do
                with the information that we collect.
            </p>

            <h4 className={'text-lg font-bold mb-4'}> TABLE OF CONTENTS </h4>

            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header1'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    1. WHAT INFORMATION DO WE COLLECT?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header2'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    2. HOW DO WE USE YOUR INFORMATION?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header3'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header4'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header5'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    5. DO WE USE GOOGLE MAPS?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header6'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header7'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header8'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    8. HOW LONG DO WE KEEP YOUR INFORMATION?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header9'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    9. HOW DO WE KEEP YOUR INFORMATION SAFE?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header10'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    10. WHAT ARE YOUR PRIVACY RIGHTS?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header11'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header12'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header13'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    13. DO WE MAKE UPDATES TO THIS NOTICE?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header14'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{' '}
                </a>{' '}
            </h4>
            <h4 className={'my-1'}>
                {' '}
                <a
                    href={'#header15'}
                    className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                    {' '}
                    15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?{' '}
                </a>{' '}
            </h4>

            <h4 id={'header1'} className={'text-lg font-bold my-4'}>
                {' '}
                1. WHAT INFORMATION DO WE COLLECT?{' '}
            </h4>

            <p>
                <p className={'my-4'}>
                    <p className={'my-1'}>
                        We collect personal information that you voluntarily provide to us when you
                        register on the Website, express an interest in obtaining information about
                        us or our products and Services, when you participate in activities on the
                        Website or otherwise when you contact us.
                    </p>
                    <p className={'my-1'}>
                        The personal information that we collect depends on the context of your
                        interactions with us and the Website, the choices you make and the products
                        and features you use. The personal information we collect may include the
                        following:
                    </p>
                    <p className={'my-1'}>
                        <strong> Personal Information Provided by You. </strong> We collect names;
                        email addresses; mailing addresses; phone numbers; job titles; usernames;
                        passwords; contact preferences; contact or authentication data; billing
                        addresses; debit/credit card numbers; and other similar information.
                    </p>
                    <p className={'my-1'}>
                        <strong> Social Media Login Data. </strong> We may provide you with the
                        option to register with us using your existing social media account details,
                        like your Facebook, Twitter or other social media account.
                    </p>
                    <p className={'my-1'}>
                        If you choose to register in this way, we will collect the Information
                        described in the section called
                        <a
                            href={'#header8'}
                            className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                            {' '}
                            "HOW DO WE HANDLE YOUR SOCIAL LOGINS"{' '}
                        </a>{' '}
                        below. All personal information that you provide to us must be true,
                        complete and accurate, and you must notify us of any changes to such
                        personal information.
                    </p>
                </p>
                Personal information you disclose to us. <br />
                <em>
                    <strong> In Short: </strong>
                    We collect personal information that you provide to us.
                </em>
                <p className={'my-4'}>
                    Information automatically collected <br />
                    <p className={'my-1'}>
                        <em>
                            <strong> In Short: </strong>
                            Some information — such as your Internet Protocol (IP) address and/or
                            browser and device characteristics — is collected automatically when you
                            visit our Website.
                        </em>
                        We automatically collect certain information when you visit, use or navigate
                        the Website.
                    </p>
                    <p className={'my-1'}>
                        This information does not reveal your specific identity (like your name or
                        contact information) but may include device and usage information, such as
                        your IP address, browser and device characteristics, operating system,
                        language preferences, referring URLs, device name, country, location,
                        information about how and when you use our Website and other technical
                        information. This information is primarily needed to maintain the security
                        and operation of our Website, and for our internal analytics and reporting
                        purposes. Like many businesses, we also collect information through cookies
                        and similar technologies.
                    </p>
                    <p className={'my-1'}>
                        The information we collect includes:
                        <strong> Log and Usage Data. </strong> Log and usage data is
                        service-related, diagnostic, usage and performance information our servers
                        automatically collect when you access or use our Website and which we record
                        in log files. Depending on how you interact with us, this log data may
                        include your IP address, device information, browser type and settings and
                        information about your activity in the Website (such as the date/time stamps
                        associated with your usage, pages and files viewed, searches and other
                        actions you take such as which features you use), device event information
                        (such as system activity, error reports (sometimes called 'crash dumps') and
                        hardware settings).
                    </p>
                    <p className={'my-1'}>
                        <strong> Device Data. </strong>
                        We collect device data such as information about your computer, phone,
                        tablet or other device you use to access the Website. Depending on the
                        device used, this device data may include information such as your IP
                        address (or proxy server), device and application identification numbers,
                        location, browser type, hardware model Internet service provider and/or
                        mobile carrier, operating system and system configuration information.
                    </p>
                    <p className={'my-1'}>
                        <strong> Location Data. </strong>
                        We collect location data such as information about your device's location,
                        which can be either precise or imprecise. How much information we collect
                        depends on the type and settings of the device you use to access the
                        Website. For example, we may use GPS and other technologies to collect
                        geolocation data that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this information either
                        by refusing access to the information or by disabling your Location setting
                        on your device. Note however, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                    </p>
                </p>
                <p className={'my-1'}>
                    Information collected from other sources In Short: We may collect limited data
                    from public databases, marketing partners, social media platforms, and other
                    outside sources. In order to enhance our ability to provide relevant marketing,
                    offers and services to you and update our records, we may obtain information
                    about you from other sources, such as public databases, joint marketing
                    partners, affiliate programs, data providers, social media platforms, as well as
                    from other third parties. This information includes mailing addresses, job
                    titles, email addresses, phone numbers, intent data (or user behavior data),
                    Internet Protocol (IP) addresses, social media profiles, social media URLs and
                    custom profiles, for purposes of targeted advertising and event promotion. If
                    you interact with us on a social media platform using your social media account
                    (e.g. Facebook or Twitter), we receive personal information about you such as
                    your name, email address, and gender. Any personal information that we collect
                    from your social media account depends on your social media account's privacy
                    settings.
                </p>
            </p>

            <h4 id={'header2'} className={'text-lg font-bold my-4'}>
                {' '}
                2. HOW DO WE USE YOUR INFORMATION?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        We process your information for purposes based on legitimate business
                        interests, the fulfillment of our contract with you, compliance with our
                        legal obligations, and/or your consent.
                    </em>
                    We use personal information collected via our Website for a variety of business
                    purposes described below. We process your personal information for these
                    purposes in reliance on our legitimate business interests, in order to enter
                    into or perform a contract with you, with your consent, and/or for compliance
                    with our legal obligations. We indicate the specific processing grounds we rely
                    on next to each purpose listed below. We use the information we collect or
                    receive:
                </p>
                <p className={'my-1'}>
                    <ul className={'list-inside list-disc'}>
                        <li>
                            <strong> To facilitate account creation and logon process. </strong>
                            If you choose to link your account with us to a third-party account
                            (such as your Google or Facebook account), we use the information you
                            allowed us to collect from those third parties to facilitate account
                            creation and logon process for the performance of the contract. See the
                            section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further
                            information.
                        </li>
                        <li>
                            <strong> To post testimonials. </strong>
                            We post testimonials on our Website that may contain personal
                            information. Prior to posting a testimonial, we will obtain your consent
                            to use your name and the content of the testimonial. If you wish to
                            update, or delete your testimonial, please contact us at
                            prabhjot@coresine.com and be sure to include your name, testimonial
                            location, and contact information.
                        </li>
                        <li>
                            <strong> Request feedback. </strong>
                            We may use your information to request feedback and to contact you about
                            your use of our Website. To enable user-to-user communications. We may
                            use your information in order to enable user-to-user communications with
                            each user's consent.
                        </li>
                        <li>
                            <strong> To manage user accounts. </strong>
                            We may use your information for the purposes of managing our account and
                            keeping it in working order.
                        </li>
                        <li>
                            <strong> To send administrative information to you. </strong>
                            We may use your personal information to send you product, service and
                            new feature information and/or information about changes to our terms,
                            conditions, and policies.
                        </li>
                        <li>
                            <strong> To protect our Services. </strong>
                            We may use your information as part of our efforts to keep our Website
                            safe and secure (for example, for fraud monitoring and prevention).
                        </li>
                        <li>
                            <strong>
                                {' '}
                                To enforce our terms, conditions and policies for business purposes,
                                to comply with legal and regulatory requirements or in connection
                                with our contract.{' '}
                            </strong>
                        </li>
                        <li>
                            <strong> To respond to legal requests and prevent harm. </strong>
                            If we receive a subpoena or other legal request, we may need to inspect
                            the data we hold to determine how to respond.
                        </li>
                        <li>
                            <strong> Fulfill and manage your orders. </strong>
                            We may use your information to fulfill and manage your orders, payments,
                            returns, and exchanges made through the Website
                        </li>
                        <li>
                            <strong> Administer prize draws and competitions. </strong>
                            We may use your information to administer prize draws and competitions
                            when you elect to participate in our competitions.
                        </li>
                        <li>
                            <strong>
                                {' '}
                                To deliver and facilitate delivery of services to the user.{' '}
                            </strong>
                            We may use your information to provide you with the requested service.
                        </li>
                        <li>
                            <strong> To respond to user inquiries/offer support to users. </strong>
                            We may use your information to respond to your inquiries and solve any
                            potential issues you might have with the use of our Services.\
                        </li>
                        <li>
                            <strong> To send you marketing and promotional communications. </strong>
                            We and/or our third-party marketing partners may use the personal
                            information you send to us for our marketing purposes, if this is in
                            accordance with your marketing preferences. For example, when expressing
                            an interest in obtaining information about us or our Website,
                            subscribing to marketing or otherwise contacting us, we will collect
                            personal information from you. You can opt-out of our marketing emails
                            at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
                        </li>
                        <li>
                            <strong> Deliver targeted advertising to you. </strong>
                            We may use your information to develop and display personalized content
                            and advertising (and work with third parties who do so) tailored to your
                            interests and/or location and to measure its effectiveness.
                        </li>
                        <li>
                            <strong> For other business purposes. </strong>
                            We may use your information for other business purposes, such as data
                            analysis, identifying usage trends, determining the effectiveness of our
                            promotional campaigns and to evaluate and improve our Website, products,
                            marketing and your experience. We may use and store this information in
                            aggregated and anonymized form so that it is not associated with
                            individual end users and does not include personal information. We will
                            not use identifiable personal information without your consent.
                        </li>
                    </ul>
                </p>
            </p>
            <h4 id={'header3'} className={'text-lg font-bold my-4'}>
                {' '}
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                    </em>
                    We only share information with your consent, to comply with laws, to provide you
                    with services, to protect your rights, or to fulfill business obligations.
                </p>
                <ul className={'list-inside list-disc'}>
                    We may process or share your data that we hold based on the following legal
                    basis:
                    <li>
                        {' '}
                        Consent: We may process your data if you have given us specific consent to
                        use your personal information for a specific purpose.{' '}
                    </li>
                    <li>
                        {' '}
                        Legitimate Interests: We may process your data when it is reasonably
                        necessary to achieve our legitimate business interests.{' '}
                    </li>
                    <li>
                        {' '}
                        Performance of a Contract: Where we have entered into a contract with you,
                        we may process your personal information to fulfill the terms of our
                        contract.{' '}
                    </li>
                    <li>
                        {' '}
                        Legal Obligations: We may disclose your information where we are legally
                        required to do so in order to comply with applicable law, governmental
                        requests, a judicial proceeding, court order, or legal process, such as in
                        response to a court order or a subpoena (including in response to public
                        authorities to meet national security or law enforcement requirements).{' '}
                    </li>
                    <li>
                        {' '}
                        Vital Interests: We may disclose your information where we believe it is
                        necessary to investigate, prevent, or take action regarding potential
                        violations of our policies, suspected fraud, situations involving potential
                        threats to the safety of any person and illegal activities, or as evidence
                        in litigation in which we are involved.{' '}
                    </li>
                    <li>
                        {' '}
                        More specifically, we may need to process your data or share your personal
                        information in the following situations:{' '}
                    </li>
                    <li>
                        {' '}
                        Business Transfers. We may share or transfer your information in connection
                        with, or during negotiations of, any merger, sale of company assets,
                        financing, or acquisition of all or a portion of our business to another
                        company.{' '}
                    </li>
                    <li>
                        {' '}
                        Affiliates. We may share your information with our affiliates, in which case
                        we will require those affiliates to honor this privacy notice. Affiliates
                        include our parent company and any subsidiaries, joint venture partners or
                        other companies that we control or that are under common control with us.{' '}
                    </li>
                    <li>
                        {' '}
                        Business Partners. We may share your information with our business partners
                        to offer you certain products, services or promotions.{' '}
                    </li>
                </ul>
            </p>

            <h4 id={'header4'} className={'text-lg font-bold my-4'}>
                {' '}
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        We may use cookies and other tracking technologies to collect and store your
                        information.
                    </em>
                </p>
                <p className={'my-1'}>
                    We may use cookies and similar tracking technologies (like web beacons and
                    pixels) to access or store information. Specific information about how we use
                    such technologies and how you can refuse certain cookies is set out in our
                    Cookie Notice.
                </p>
            </p>

            <h4 id={'header5'} className={'text-lg font-bold my-4'}>
                {' '}
                5. DO WE USE GOOGLE MAPS?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        Yes, we use Google Maps for the purpose of providing better service.
                    </em>
                </p>
                <p className={'my-1'}>
                    This Website uses Google Maps APIs which is subject to Google’s Terms of
                    Service. You may find the Google Maps APIs Terms of Service here. To find out
                    more about Google’s Privacy Policy, please refer to this link. We obtain and
                    store on your device ('cache') your location for one (1) months. You may revoke
                    your consent anytime by contacting us at the contact details provided at the end
                    of this document.
                </p>
            </p>

            <h4 id={'header6'} className={'text-lg font-bold my-4'}>
                {' '}
                6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        If you choose to register or log in to our services using a social media
                        account, we may have access to certain information about you.
                    </em>
                </p>
                <p className={'my-1'}>
                    Our Website offers you the ability to register and login using your third-party
                    social media account details (like your Facebook or Twitter logins). Where you
                    choose to do this, we will receive certain profile information about you from
                    your social media provider. The profile Information we receive may vary
                    depending on the social media provider concerned, but will often include your
                    name, email address, friends list, profile picture as well as other information
                    you choose to make public on such social media platform. We will use the
                    information we receive only for the purposes that are described in this privacy
                    notice or that are otherwise made clear to you on the relevant Website. Please
                    note that we do not control, and are not responsible for, other uses of your
                    personal information by your third-party social media provider. We recommend
                    that you review their privacy notice to understand how they collect, use and
                    share your personal information, and how you can set your privacy preferences on
                    their sites and apps.
                </p>
            </p>
            <h4 id={'header7'} className={'text-lg font-bold my-4'}>
                {' '}
                7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        We are not responsible for the safety of any information that you share with
                        third-party providers who advertise, but are not affiliated with, our
                        Website.
                    </em>
                </p>
                <p className={'my-1'}>
                    The Website may contain advertisements from third parties that are not
                    affiliated with us and which may link to other websites, online services or
                    mobile applications. We cannot guarantee the safety and privacy of data you
                    provide to any third parties. Any data collected by third parties is not covered
                    by this privacy notice. We are not responsible for the content or privacy and
                    security practices and policies of any third parties, including other websites,
                    services or applications that may be linked to or from the Website. You should
                    review the policies of such third parties and contact them directly to respond
                    to your questions.
                </p>
            </p>

            <h4 id={'header8'} className={'text-lg font-bold my-4'}>
                {' '}
                8. HOW LONG DO WE KEEP YOUR INFORMATION?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        We will only keep your personal information for as long as it is necessary
                        for the purposes set out in this privacy notice, unless a longer retention
                        period is required or permitted by law (such as tax, accounting or other
                        legal requirements). No purpose in this notice will require us keeping your
                        personal information for longer than the period of time in which users have
                        an account with us. When we have no ongoing legitimate business need to
                        process your personal information, we will either delete or anonymize such
                        information, or, if this is not possible (for example, because your personal
                        information has been stored in backup archives), then we will securely store
                        your personal information and isolate it from any further processing until
                        deletion is possible.
                    </em>
                </p>
            </p>

            <h4 id={'header9'} className={'text-lg font-bold my-4'}>
                {' '}
                9. HOW DO WE KEEP YOUR INFORMATION SAFE?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        We aim to protect your personal information through a system of
                        organizational and technical security measures.
                    </em>
                </p>
                <p className={'my-1'}>
                    We have implemented appropriate technical and organizational security measures
                    designed to protect the security of any personal information we process.
                    However, despite our safeguards and efforts to secure your information, no
                    electronic transmission over the Internet or information storage technology can
                    be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other unauthorized third parties will not be able to defeat
                    our security, and improperly collect, access, steal, or modify your information.
                    Although we will do our best to protect your personal information, transmission
                    of personal information to and from our Website is at your own risk. You should
                    only access the Website within a secure environment.
                </p>
            </p>

            <h4 id={'header10'} className={'text-lg font-bold my-4'}>
                {' '}
                10. WHAT ARE YOUR PRIVACY RIGHTS?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        In some regions, such as the European Economic Area, you have rights that
                        allow you greater access to and control over your personal information. You
                        may review, change, or terminate your account at any time.
                    </em>
                </p>
                <p className={'my-1'}>
                    In some regions (like the European Economic Area), you have certain rights under
                    applicable data protection laws. These may include the right (i) to request
                    access and obtain a copy of your personal information, (ii) to request
                    rectification or erasure; (iii) to restrict the processing of your personal
                    information; and (iv) if applicable, to data portability. In certain
                    circumstances, you may also have the right to object to the processing of your
                    personal information. To make such a request, please use the contact details
                    provided below. We will consider and act upon any request in accordance with
                    applicable data protection laws.
                </p>
                <p className={'my-1'}>
                    If we are relying on your consent to process your personal information, you have
                    the right to withdraw your consent at any time. Please note however that this
                    will not affect the lawfulness of the processing before its withdrawal, nor will
                    it affect the processing of your personal information conducted in reliance on
                    lawful processing grounds other than consent. If you are a resident in the
                    European Economic Area and you believe we are unlawfully processing your
                    personal information, you also have the right to complain to your local data
                    protection supervisory authority. You can find their contact details here:
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                </p>
                <p className={'my-1'}>
                    If you are a resident in Switzerland, the contact details for the data
                    protection authorities are available here:
                    https://www.edoeb.admin.ch/edoeb/en/home.html.
                </p>
                <p className={'my-1'}>
                    If you have questions or comments about your privacy rights, you may email us at
                    prabhjot@coresine.com.
                </p>
                <p className={'my-1'}>
                    <strong> Account Information </strong>
                    If you would at any time like to review or change the information in your
                    account or terminate your account, you can: <br />
                    <ul className={'list-inside list-disc'}>
                        <li> Log in to your account settings and update your user account. </li>
                        <li> Contact us using the contact information provided. </li>
                    </ul>
                </p>
                <p className={'my-1'}>
                    Upon your request to terminate your account, we will deactivate or delete your
                    account and information from our active databases. However, we may retain some
                    information in our files to prevent fraud, troubleshoot problems, assist with
                    any investigations, enforce our Terms of Use and/or comply with applicable legal
                    requirements.
                </p>
                <p className={'my-1'}>
                    <strong className={'underline'}> Cookies and similar technologies: </strong>
                    Most Web browsers are set to accept cookies by default. If you prefer, you can
                    usually choose to set your browser to remove cookies and to reject cookies. If
                    you choose to remove cookies or reject cookies, this could affect certain
                    features or services of our Website. To opt-out of interest-based advertising by
                    advertisers on our Website visit http://www.aboutads.info/choices/.
                </p>
                <p className={'my-1'}>
                    <strong className={'underline'}> Opting out of email marketing: </strong>
                    You can unsubscribe from our marketing email list at any time by clicking on the
                    unsubscribe link in the emails that we send or by contacting us using the
                    details provided below. You will then be removed from the marketing email list —
                    however, we may still communicate with you, for example to send you
                    service-related emails that are necessary for the administration and use of your
                    account, to respond to service requests, or for other non-marketing purposes. To
                    otherwise opt-out, you may:
                    <ul className={'list-inside list-disc'}>
                        <li> Access your account settings and update your preferences. </li>
                        <li> Contact us using the contact information provided. </li>
                    </ul>
                </p>
            </p>

            <h4 id={'header11'} className={'text-lg font-bold my-4'}>
                {' '}
                11. CONTROLS FOR DO-NOT-TRACK FEATURES{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    Most web browsers and some mobile operating systems and mobile applications
                    include a Do-Not-Track ("DNT") feature or setting you can activate to signal
                    your privacy preference not to have data about your online browsing activities
                    monitored and collected. At this stage no uniform technology standard for
                    recognizing and implementing DNT signals has been finalized. As such, we do not
                    currently respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked online. If a standard
                    for online tracking is adopted that we must follow in the future, we will inform
                    you about that practice in a revised version of this privacy notice.
                </p>
            </p>

            <h4 id={'header12'} className={'text-lg font-bold my-4'}>
                {' '}
                12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-1'}>
                    <em>
                        <strong> In Short: </strong>
                        Yes, if you are a resident of California, you are granted specific rights
                        regarding access to your personal information.
                    </em>
                </p>
                <p className={'my-1'}>
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law,
                    permits our users who are California residents to request and obtain from us,
                    once a year and free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for direct marketing purposes
                    and the names and addresses of all third parties with which we shared personal
                    information in the immediately preceding calendar year. If you are a California
                    resident and would like to make such a request, please submit your request in
                    writing to us using the contact information provided below.
                </p>
                <p className={'my-1'}>
                    If you are under 18 years of age, reside in California, and have a registered
                    account with the Website, you have the right to request removal of unwanted data
                    that you publicly post on the Website. To request removal of such data, please
                    contact us using the contact information provided below, and include the email
                    address associated with your account and a statement that you reside in
                    California. We will make sure the data is not publicly displayed on the Website,
                    but please be aware that the data may not be completely or comprehensively
                    removed from all our systems (e.g. backups, etc.).
                </p>
                <p className={'my-1'}>
                    <strong> CCPA Privacy Notice </strong>
                    The California Code of Regulations defines a "resident" as:
                    <ul className={'list-inside'}>
                        <li>
                            {' '}
                            (1) every individual who is in the State of California for other than a
                            temporary or transitory purpose and{' '}
                        </li>
                        <li>
                            {' '}
                            (2) every individual who is domiciled in the State of California who is
                            outside the State of California for a temporary or transitory purpose{' '}
                        </li>
                    </ul>
                </p>
                <p className={'my-1'}>All other individuals are defined as "non-residents."</p>
                <p className={'my-1'}>
                    If this definition of "resident" applies to you, we must adhere to certain
                    rights and obligations regarding your personal information.
                </p>
                <p className={'my-1'}>
                    <strong> What categories of personal information do we collect? </strong>
                </p>
                <p className={'my-1'}>
                    We have collected the following categories of personal information in the past
                    twelve (12) months:
                </p>
            </p>
            <HTMLTable striped={true}>
                <thead>
                    <tr>
                        <th> Category </th>
                        <th> Examples </th>
                        <th> Collected </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> A. Identifiers </td>
                        <td>
                            {' '}
                            Contact details, such as real name, alias, postal address, telephone or
                            mobile contact number, unique personal identifier, online identifier,
                            Internet Protocol address, email address and account name{' '}
                        </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td>
                            {' '}
                            B. Personal information categories listed in the California Customer
                            Records statute{' '}
                        </td>
                        <td>
                            {' '}
                            Name, contact information, education, employment, employment history and
                            financial information{' '}
                        </td>
                        <td> YES </td>
                    </tr>
                    <tr>
                        <td>
                            {' '}
                            C. Protected classification characteristics under California or federal
                            law{' '}
                        </td>
                        <td> Gender and date of birth </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> D. Commercial information </td>
                        <td>
                            {' '}
                            Transaction information, purchase history, financial details and payment
                            information{' '}
                        </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> E. Biometric information </td>
                        <td> Fingerprints and voiceprints </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> F. Internet or other similar network activity </td>
                        <td>
                            {' '}
                            Browsing history, search history, online behavior, interest data, and
                            interactions with our and other websites, applications, systems and
                            advertisements{' '}
                        </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> G. Geolocation data </td>
                        <td> Device location </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td>
                            {' '}
                            H. Audio, electronic, visual, thermal, olfactory, or similar information{' '}
                        </td>
                        <td>
                            {' '}
                            Images and audio, video or call recordings created in connection with
                            our business activities{' '}
                        </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> I. Professional or employment-related information </td>
                        <td>
                            {' '}
                            Business contact details in order to provide you our services at a
                            business level, job title as well as work history and professional
                            qualifications if you apply for a job with us{' '}
                        </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> J. Education Information </td>
                        <td> Student records and directory information </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> A. Identifiers </td>
                        <td>
                            {' '}
                            Contact details, such as real name, alias, postal address, telephone or
                            mobile contact number, unique personal identifier, online identifier,
                            Internet Protocol address, email address and account name{' '}
                        </td>
                        <td> NO </td>
                    </tr>
                    <tr>
                        <td> K. Inferences drawn from other personal information </td>
                        <td>
                            {' '}
                            Inferences drawn from any of the collected personal information listed
                            above to create a profile or summary about, for example, an individual’s
                            preferences and characteristics{' '}
                        </td>
                        <td> YES </td>
                    </tr>
                </tbody>
            </HTMLTable>
            <p className={'my-1'}>
                We may also collect other personal information outside of these categories instances
                where you interact with us in-person, online, or by phone or mail in the context of:
                <ul className={'list-inside list-disc'}>
                    <li> Receiving help through our customer support channels; </li>
                    <li> Participation in customer surveys or contests; and </li>
                    <li>
                        {' '}
                        Facilitation in the delivery of our Services and to respond to your
                        inquiries.{' '}
                    </li>
                </ul>
            </p>
            <p className={'my-1'}>
                <strong> How do we use and share your personal information? </strong>
                More information about our data collection and sharing practices can be found in
                this privacy notice.
            </p>
            <p className={'my-1'}>
                You may contact us by email at prabhjot@coresine.com, or by referring to the contact
                details at the bottom of this document.
            </p>
            <p className={'my-1'}>
                If you are using an authorized agent to exercise your right to opt-out we may deny a
                request if the authorized agent does not submit proof that they have been validly
                authorized to act on your behalf.
            </p>
            <p className={'my-1'}>
                You may contact us by email at prabhjot@coresine.com, or by referring to the contact
                details at the bottom of this document.
            </p>
            <p className={'my-1'}>
                <strong> Will your information be shared with anyone else? </strong>
            </p>
            <p className={'my-1'}>
                We may disclose your personal information with our service providers pursuant to a
                written contract between us and each service provider. Each service provider is a
                for-profit entity that processes the information on our behalf.
            </p>
            <p className={'my-1'}>
                We may use your personal information for our own business purposes, such as for
                undertaking internal research for technological development and demonstration. This
                is not considered to be "selling" of your personal data.
            </p>
            <p className={'my-1'}>
                Coresine Oy has not disclosed or sold any personal information to third parties for
                a business or commercial purpose in the preceding 12 months. Coresine Oy will not
                sell personal information in the future belonging to website visitors, users and
                other consumers.
            </p>
            <p className={'my-1'}>
                <strong> Your rights with respect to your personal data </strong>
            </p>
            <p className={'my-1'}>
                <span className={'underline'}>
                    {' '}
                    Right to request deletion of the data - Request to delete{' '}
                </span>
            </p>
            <p className={'my-1'}>
                You can ask for the deletion of your personal information. If you ask us to delete
                your personal information, we will respect your request and delete your personal
                information, subject to certain exceptions provided by law, such as (but not limited
                to) the exercise by another consumer of his or her right to free speech, our
                compliance requirements resulting from a legal obligation or any processing that may
                be required to protect against illegal activities.
            </p>
            <p className={'my-1'}>
                <span className={'underline'}> Right to be informed - Request to know </span>
            </p>
            <p className={'my-1'}>
                Depending on the circumstances, you have a right to know:
                <ul className={'list-inside list-disc'}>
                    <li> whether we collect and use your personal information; </li>
                    <li> the categories of personal information that we collect; </li>
                    <li> the purposes for which the collected personal information is used; </li>
                    <li> whether we sell your personal information to third parties; </li>
                    <li>
                        {' '}
                        the categories of personal information that we sold or disclosed for a
                        business purpose;{' '}
                    </li>
                    <li>
                        {' '}
                        the categories of third parties to whom the personal information was sold or
                        disclosed for a business purpose; and{' '}
                    </li>
                    <li>
                        {' '}
                        the business or commercial purpose for collecting or selling personal
                        information.{' '}
                    </li>
                </ul>
            </p>
            <p className={'my-1'}>
                In accordance with applicable law, we are not obligated to provide or delete
                consumer information that is de-identified in response to a consumer request or to
                re-identify individual data to verify a consumer request.
            </p>
            <p className={'my-1'}>
                <span className={'underline'}>
                    {' '}
                    Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights{' '}
                </span>
            </p>
            <p className={'my-1'}>
                We will not discriminate against you if you exercise your privacy rights.
            </p>
            <p className={'my-1'}>
                <span className={'underline'}> Verification process </span>
            </p>
            <p className={'my-1'}>
                Upon receiving your request, we will need to verify your identity to determine you
                are the same person about whom we have the information in our system. These
                verification efforts require us to ask you to provide information so that we can
                match it with information you have previously provided us. For instance, depending
                on the type of request you submit, we may ask you to provide certain information so
                that we can match the information you provide with the information we already have
                on file, or we may contact you through a communication method (e.g. phone or email)
                that you have previously provided to us. We may also use other verification methods
                as the circumstances dictate.
            </p>
            <p className={'my-1'}>
                We will only use personal information provided in your request to verify your
                identity or authority to make the request. To the extent possible, we will avoid
                requesting additional information from you for the purposes of verification. If,
                however, if we cannot verify your identity from the information already maintained
                by us, we may request that you provide additional information for the purposes of
                verifying your identity, and for security or fraud-prevention purposes. We will
                delete such additionally provided information as soon as we finish verifying you.
            </p>
            <p className={'my-1'}>
                <span className={'underline'}> Other privacy rights </span>
                <ul className={'list-inside list-disc'}>
                    <li> you may object to the processing of your personal data </li>
                    <li>
                        {' '}
                        you may request correction of your personal data if it is incorrect or no
                        longer relevant, or ask to restrict the processing of the data{' '}
                    </li>
                    <li>
                        {' '}
                        you can designate an authorized agent to make a request under the CCPA on
                        your behalf. We may deny a request from an authorized agent that does not
                        submit proof that they have been validly authorized to act on your behalf in
                        accordance with the CCPA.{' '}
                    </li>
                    <li>
                        {' '}
                        you may request to opt-out from future selling of your personal information
                        to third parties. Upon receiving a request to opt-out, we will act upon the
                        request as soon as feasibly possible, but no later than 15 days from the
                        date of the request submission.{' '}
                    </li>
                </ul>
            </p>
            <p className={'my-1'}>
                To exercise these rights, you can contact us by email at prabhjot@coresine.com, or
                by referring to the contact details at the bottom of this document. If you have a
                complaint about how we handle your data, we would like to hear from you.
            </p>
            <p className={'my-1'}>
                <strong> Financial Incentives </strong>
            </p>
            <p className={'my-1'}>
                "Financial incentive" means a program, benefit, or other offering, including
                payments to consumers as compensation, for the disclosure, deletion, or sale of
                personal information.
            </p>
            <p className={'my-1'}>
                The law permits financial incentives or a price or service difference if:
                <ul className={'list-inside list-disc'}>
                    <li>
                        {' '}
                        a business has calculated a good-faith estimate of the value of the
                        consumer’s data that forms the basis for offering the financial incentive or
                        price or service difference, and{' '}
                    </li>
                    <li>
                        {' '}
                        if a description of the method the business used to calculate the value of
                        the consumer’s data.{' '}
                    </li>
                </ul>
            </p>
            <p className={'my-1'}>
                We may decide to offer a financial incentive (e.g. price or service difference) in
                exchange for the retention or sale of a consumer’s personal information.
            </p>
            <p className={'my-1'}>
                If we decide to offer a financial incentive, we will notify you of such financial
                incentive and explain the price difference, as well as material terms of the
                financial incentive or price of service difference, including the categories of
                personal information that are implicated by the financial incentive or price or
                service difference.
            </p>
            <p className={'my-1'}>
                If you choose to participate in the financial incentive you can withdraw from the
                financial incentive at any time by emailing us at prabhjot@coresine.com, or by
                referring to the contact details at the bottom of this document.
            </p>

            <h4 id={'header13'} className={'text-lg font-bold my-4'}>
                {' '}
                13. DO WE MAKE UPDATES TO THIS NOTICE?{' '}
            </h4>
            <p className={'my-4'}>
                <em>
                    <strong> In Short: </strong>
                    Yes, we will update this notice as necessary to stay compliant with relevant
                    laws.
                </em>
                <p className={'my-1'}>
                    We may update this privacy notice from time to time. The updated version will be
                    indicated by an updated "Revised" date and the updated version will be effective
                    as soon as it is accessible. If we make material changes to this privacy notice,
                    we may notify you either by prominently posting a notice of such changes or by
                    directly sending you a notification. We encourage you to review this privacy
                    notice frequently to be informed of how we are protecting your information.
                </p>
            </p>
            <h4 id={'header14'} className={'text-lg font-bold my-4'}>
                {' '}
                14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{' '}
            </h4>
            <p className={'my-4'}>
                <p className={'my-2'}>
                    If you have questions or comments about this notice, you may email us at
                    prabhjot@coresine.com or by post to:
                </p>
                Coresine Oy <br />
                <p className={'my-2 mb-3'}>__________</p>
                Oulu, Northern Osthrobothnia 90570 Finland
            </p>
            <h4 id={'header15'} className={'text-lg font-bold my-4'}>
                {' '}
                15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?{' '}
            </h4>
            <p className={'my-4'}>
                Based on the applicable laws of your country, you may have the right to request
                access to the personal information we collect from you, change that information, or
                delete it in some circumstances. To request to review, update, or delete your
                personal information, please submit a request form by clicking here. We will respond
                to your request within 30 days.
            </p>
            <p className={'mt-8'}>
                This privacy policy was created using Termly’s Privacy Policy Generator.
            </p>
        </div>
    );
};

export default CookieDocument;
