import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../utils/theming/theming';

type PropsPadding = {
    padding: string;
    templateColumnsOnMQ0?: string;
    templateColumnsOnMQ1?: string;
    templateColumnsOnMQ2?: string;
    gap?: string;
};

/**
 * A styled component that layouts a grid with one to two column depending on the screen width
 */
const OneToTwoColumnsGridWrapper = styled.div`
    display: grid;
    grid-auto-columns: 100%;
    grid-auto-rows: auto;
    padding: 0.1rem;
    gap: ${(props: PropsPadding) => (props.gap ? props.gap : '0.5rem')};
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    padding: ${(props: PropsPadding) => props.padding};
    ${theme.layout.mq[0]} {
        grid-template-columns: ${(props: PropsPadding) =>
            props.templateColumnsOnMQ0 ? props.templateColumnsOnMQ0 : '1fr 1fr'};
    }
    ${theme.layout.mq[1]} {
        grid-template-columns: ${(props: PropsPadding) =>
            props.templateColumnsOnMQ1 ? props.templateColumnsOnMQ1 : '1fr 1fr 1fr'};
    }
    ${theme.layout.mq[2]} {
        grid-template-columns: ${(props: PropsPadding) =>
            props.templateColumnsOnMQ2 ? props.templateColumnsOnMQ2 : '1fr 1fr 1fr'};
    }
`;

export default OneToTwoColumnsGridWrapper;
