import axios, { AxiosRequestConfig } from 'axios';
import { IContactFormFill } from './types';

export class Client {
    url = process.env.NEXT_PUBLIC_CONTACT_API;
    constructor(url: string | null = null) {
        if (url) {
            this.url = url;
        }
        console.log(this.url);
    }

    /**
     * Submit a form
     * @param data {IContactFormFill} Contact Form fill data
     * @param config Axios config
     */
    public async submit(data: IContactFormFill, config?: AxiosRequestConfig): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post<IContactFormFill>(
                    `${this.url}`,
                    { ...data },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                resolve(res.data);
            } catch (err) {
                reject(err);
            }
        });
    }
}
