import React from 'react';
import Theme from '../../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

const HelperTextOnError: React.FunctionComponent = ({ children }) => {
    const theme: Theme = useTheme();

    return (
        <p
            css={css`
                color: ${theme.colors.danger};
            `}>
            {children}
        </p>
    );
};

export default HelperTextOnError;
