import React, { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/core';
import { Card, IPanel, PanelStack } from '@blueprintjs/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../utils/theming/theme-type';
import { CurrentPanel } from '../protected-route';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Actions } from '../../index';
import CoresineLogo from '../../../../public/coresine-logo.svg';

export const EMAIL_PANEL = 'Registration';
export const PASSWORD_PANEL = 'Password';
export const CONFIRMATION_CODE_PANEL = 'Confirmation Code';
export const USERNAME_PANEL = 'Username';
export const SUCCESS_PANEL = 'Success!';

interface IRegistrationPanel {
  errorHeight: number;
  setCurrentPanel: Dispatch<SetStateAction<CurrentPanel>>
  appendPanelHandler: Dispatch<any>
  removeLastPanelHandler: () => void
  currentRegistrationPanelStack: IPanel[]
  emailConfirmationError: Error
  usernameError: Error
}

const RegistrationPanel: React.FunctionComponent<IRegistrationPanel> = (
  { errorHeight,
    appendPanelHandler,
    usernameError,
    removeLastPanelHandler,
    emailConfirmationError,
    currentRegistrationPanelStack }) => {

  const theme: Theme = useTheme();

  const theLastPanel: number = currentRegistrationPanelStack.length - 1;

  const onOpenHandler = (newPanel: IPanel) => {
    appendPanelHandler(newPanel);
  }

  const onCloseHandler = () => {
    removeLastPanelHandler();
  }

  // TODO Add Callout components instead of toaster for a better feedback and error visualization

  return (
    <Card className={'flex flex-col items-center p-2 md:p-5'}>
      <CoresineLogo className={'w-24 h-6 mb-4'} />
      <PanelStack renderActivePanelOnly={false}
                  onClose={onCloseHandler}
                  onOpen={onOpenHandler}
                  stack={currentRegistrationPanelStack}
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 18rem;
                    overflow-y: hidden;
                    height:
                      calc(
                        ${currentRegistrationPanelStack[theLastPanel].title === EMAIL_PANEL && '19rem'}
                        ${currentRegistrationPanelStack[theLastPanel].title === PASSWORD_PANEL && '26rem'}
                        ${currentRegistrationPanelStack[theLastPanel].title === USERNAME_PANEL && '13.5rem'}
                        ${currentRegistrationPanelStack[theLastPanel].title === CONFIRMATION_CODE_PANEL && '17rem'}
                        ${currentRegistrationPanelStack[theLastPanel].title === SUCCESS_PANEL && '17rem'}
                        ${(emailConfirmationError || usernameError ) && `+ ${errorHeight}rem`}
                      );
                    transition: all ease-in-out 0.4s;
                    .bp3-panel-stack-header {
                      box-shadow: none;
                      margin-bottom: 2.5rem;
                      .bp3-heading {
                        font-size: ${theme.fontSize.header};
                      }

                        .bp3-button-text {
                          display: none;
                        }
                      }
                      .bp3-panel-stack-view {
                        border-right: 0;
                      }
                      ${theme.layout.mq[0]} {
                        width: 20rem;
                        height:
                          calc(
                            ${currentRegistrationPanelStack[theLastPanel].title === EMAIL_PANEL && '19rem'}
                            ${currentRegistrationPanelStack[theLastPanel].title === PASSWORD_PANEL && '24rem'}
                            ${currentRegistrationPanelStack[theLastPanel].title === USERNAME_PANEL && '13.5rem'}
                            ${currentRegistrationPanelStack[theLastPanel].title === CONFIRMATION_CODE_PANEL && '17rem'}
                            ${currentRegistrationPanelStack[theLastPanel].title === SUCCESS_PANEL && '17rem'}
                            ${(emailConfirmationError || usernameError ) && `+ ${usernameError ? errorHeight + 3  : errorHeight}rem`}
                          );
                      }
                    `} />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    currentRegistrationPanelStack: state.authState?.currentRegistrationPanelStack,
    emailConfirmationError: state.authState?.registrationError.emailConfirmationError,
    usernameError: state.authState?.registrationError.usernameError,
    errorHeight: state.authState?.errorHeight
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    appendPanelHandler: (newPanel: IPanel) => dispatch(Actions.appendPanel(newPanel)),
    removeLastPanelHandler: () => dispatch(Actions.removeLastPanel()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPanel);
