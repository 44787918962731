import React from 'react';
import { ButtonGroup, Intent } from '@blueprintjs/core';
import { Button } from '@components/ui';
import { Router, useTranslation } from '../../utils/i18n/i18n';

interface ILinksComponent {
    orientation: 'horizontal' | 'vertical';
}

const LinksComponent = (props: ILinksComponent) => {
    const { t } = useTranslation();

    const pushToRoute = (route: string): void => {
        Router.push(route);
    };

    return (
        <nav className={'flex flex-col'}>
            <ButtonGroup
                vertical={props.orientation === 'vertical'}
                large={props.orientation === 'vertical'}
                alignText={'left'}>
                <Button
                    intent={Intent.PRIMARY}
                    minimal={true}
                    onClick={() => pushToRoute('/for-customers')}
                    text={t('header:landing-page-header.for-customers-page-link')}
                />
                <Button
                    intent={Intent.PRIMARY}
                    minimal={true}
                    onClick={() => pushToRoute('/for-partners')}
                    text={t('header:landing-page-header.for-partners-page-link')}
                />
                <Button
                    intent={Intent.PRIMARY}
                    minimal={true}
                    onClick={() => pushToRoute('/about-us')}
                    text={t('header:landing-page-header.about-us-page-link')}
                />
                <Button
                    intent={Intent.PRIMARY}
                    minimal={true}
                    onClick={() => pushToRoute('/contacts')}
                    text={t('header:landing-page-header.contacts-page-link')}
                />
            </ButtonGroup>
        </nav>
    );
};

export default LinksComponent;
