import React, { Dispatch, SetStateAction, useState } from 'react';
import { css } from '@emotion/core';
import { Card, IPanel, PanelStack } from '@blueprintjs/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../utils/theming/theme-type';
import { CurrentPanel } from '../protected-route';
import { connect } from 'react-redux';
import EmailPasswordPanel from './components/login-form/email-password-panel';
import CoresineLogo from '../../../../public/coresine-logo.svg';

export const LOGIN_EMAIL_PANEL = 'Login';
export const LOGIN_PASSWORD_PANEL = 'Password'

interface ILoginPanel {
  errorHeight: number;
  setCurrentPanel: Dispatch<SetStateAction<CurrentPanel>>
  loginError: Error
}

const LoginPanel: React.FunctionComponent<ILoginPanel> = (
  { setCurrentPanel, loginError, errorHeight }) => {

  const theme: Theme = useTheme();

  const onOpenHandler = (newPanel: IPanel) => {
    setCurrentPanelStack([...currentPanelStack, newPanel])
  }

  const onCloseHandler = (): void => {
    setCurrentPanelStack(currentPanelStack.slice(0, -1));
  }

  const [currentPanelStack, setCurrentPanelStack] = useState<IPanel[]>([
    { component: EmailPasswordPanel,
      props: { setCurrentPanel },
      title: LOGIN_EMAIL_PANEL } ]);

  const theLastPanel: number = currentPanelStack.length - 1;

  return (
    <Card className={'flex flex-col items-center p-2 md:p-5'}>
      <CoresineLogo className={'w-24 h-6 mb-4'} />
      <PanelStack renderActivePanelOnly={false}
                  onClose={onCloseHandler}
                  onOpen={onOpenHandler}
                  stack={currentPanelStack}
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 18rem;
                    height:
                      calc(
                        ${currentPanelStack[theLastPanel].title === LOGIN_EMAIL_PANEL && '22rem'}
                        ${loginError && `+ ${errorHeight}rem`}
                      );
                    .bp3-panel-stack-header {
                      box-shadow: none;
                      margin-bottom: 2.5rem;
                      .bp3-heading {
                        font-size: ${theme.fontSize.header};
                      }

                      .bp3-button-text {
                        display: none;
                      }
                    }
                    .bp3-panel-stack-view {
                      border-right: 0;
                    }
                    ${theme.layout.mq[0]} {
                      width: 20rem;
                      height:
                        calc(
                          ${currentPanelStack[theLastPanel].title === LOGIN_EMAIL_PANEL && '22rem'}
                          ${loginError && `+ ${errorHeight}rem`}
                        );
                    }
                  `} />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    errorHeight: state.authState.errorHeight,
    loginError: state.authState.loginError
  }
}


export default connect(mapStateToProps, null)(LoginPanel);
