import React from 'react';
import { Tabs } from '@coresine/ui';
import { ITabsProps } from '@blueprintjs/core';
import { theme } from '../../../utils/theming/theming';

type Props = ITabsProps;

const CustomTabs: React.FC<Props> = (props) => {
    return (
        <Tabs {...props} theme={theme}>
            {props.children}
        </Tabs>
    );
};

export default CustomTabs;
