import React from 'react';
import { css } from '@emotion/core';

const DividingCircle: React.FunctionComponent = () => {
    return (
        <span
            css={css`
                width: 0.25rem;
                height: 0.25rem;
                background-color: #bbbbbb;
                border-radius: 0.5rem;
                margin: 0 0.5rem;
            `}
        />
    );
};

export default DividingCircle;
