import { ActionCreator, Dispatch } from 'redux';
import * as Actions from './actions';
import { ThunkAction } from 'redux-thunk';
import {
  checkIfUsernameExists,
  confirmRegister,
  login,
  register,
  resendConfirmationCode
} from '@services/auth/client';

export const loginAsync: ActionCreator<ThunkAction<
  any,
  any,
  any,
  any
  >>  = (email, password) => {
  return async (dispatch: Dispatch) => {

    const authData = {
      email,
      password
    }
    login(authData).then((res: any) => {
      dispatch(Actions.loginSucceeded(res.data));
    }).catch((error: Error) => {
      dispatch(Actions.loginFailed(error));
    });
  }
}


export const registrationAsync: ActionCreator<ThunkAction<
  any,
  any,
  any,
  any
  >>  = ( email, password ) => {
  return async (dispatch: Dispatch) => {
    const authData = {
      email,
      password
    }
    register(authData).then((res) => {
      // console.log('res.user: ', res.user);
      dispatch(Actions.registrationSucceeded(res.user));
    }).catch((error: Error) => {
      dispatch(Actions.registrationFailed(error));
    });
  }
}

export const checkIfUsernameExistsAsync: ActionCreator<ThunkAction<
  any,
  any,
  any,
  any
  >>  = ( username ) => {
  return async (dispatch: Dispatch) => {
    checkIfUsernameExists(username).then((res: any) => {
      // TODO This piece of code should be modified later as well
      if ( res ) {
        dispatch(Actions.usernameAlreadyExists());
      } else {
        dispatch(Actions.usernameDoesNotExist());
      }
    }).catch((error: Error) => {
      dispatch(Actions.usernameExistsFailed(error));
    });
  }
}

export const emailConfirmationAsync: ActionCreator<ThunkAction<
  any,
  any,
  any,
  any
  >>  = ( emailAndCode ) => {
  return async (dispatch: Dispatch) => {
    // TODO Specify the res type
    console.log('emailAndCode: ', emailAndCode);
    confirmRegister(emailAndCode).then((res: any) => {
      dispatch(Actions.emailConfirmationSucceeded(res.code));
    }).catch((error: Error) => {
      dispatch(Actions.emailConfirmationFailed(error));
    });
  }
}

export const resendEmailConfirmationAsync: ActionCreator<ThunkAction<
  any,
  any,
  any,
  any
  >>  = ( email: string ) => {
  return async (dispatch: Dispatch) => {
    // TODO Specify the res type
    resendConfirmationCode(email).then((res: any) => {
      dispatch(Actions.resendEmailConfirmationSucceeded(res.code));
    }).catch((error: Error) => {
      dispatch(Actions.resendEmailConfirmationFailed(error));
    });
  }
}
