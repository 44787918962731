import React from 'react';
import styled from '@emotion/styled';

const MainWrapper = styled.main`
    margin-top: 3rem;
    background-color: white;
    display: flex;
    justify-content: center;
`;

export default MainWrapper;
