import React from 'react';
import LinksComponent from './links-component';

const LandingPageHeaderButtons: React.FunctionComponent = () => {
    return (
        <div className={'hidden md:flex'}>
            <LinksComponent orientation={'horizontal'} />
        </div>
    );
};

export default LandingPageHeaderButtons;
