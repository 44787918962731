import React from 'react';
import { Intent, Position, Tooltip } from '@blueprintjs/core';
import { Button } from '@components/ui';
import { IconNames } from '@blueprintjs/icons';
import useMedia from 'use-media';

const NotificationsButton: React.FunctionComponent = () => {
    const isMobile: boolean = useMedia({ maxWidth: 768 });

    if (isMobile) {
        return (
            <Button
                icon={IconNames.NOTIFICATIONS}
                large={true}
                text={'Notifications'}
                minimal={true}
                alignText={'left'}
                disabled={true}
                intent={Intent.PRIMARY}
            />
        );
    }
    if (!isMobile) {
        return (
            <Tooltip
                content={'Notifications'}
                intent={Intent.PRIMARY}
                lazy={true}
                disabled={true}
                position={Position.BOTTOM}>
                <Button
                    icon={IconNames.NOTIFICATIONS}
                    large={true}
                    disabled={true}
                    minimal={true}
                    intent={Intent.PRIMARY}
                />
            </Tooltip>
        );
    }
};

export default NotificationsButton;
