import { Client as ContactServiceClient } from '../../services/contact-service';
import { IContactFormFill } from '../../services/contact-service/types';
import { ActionCreator, Dispatch } from 'redux';
import * as Actions from './actions';
import { ThunkAction } from 'redux-thunk';
import { Event, Events } from '../../services/analytics';

export async function submitContactFormRequest(data: IContactFormFill) {
    const client = new ContactServiceClient();
    return client.submit(data);
}

export const submitContactFormAsync: ActionCreator<ThunkAction<any, any, any, any>> = (
    form: IContactFormFill
) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(Actions.submitContactFormAsync(form));
            const data = await submitContactFormRequest(form);
            Event.logEvent(Events.LANDING_PAGE_CONTACT_FORM_SUBMIT);
            return dispatch(Actions.submitContactFormSucceeded(data));
        } catch (err) {
            return dispatch(Actions.submitContactFormFailed(err));
        }
    };
};
